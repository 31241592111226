// import { useMediaQuery } from "react-responsive";
import { useEffect } from "react";
import propTypes from "prop-types";
import {
  Chart as ChartJS,
  LinearScale,
  CategoryScale,
  BarElement,
  PointElement,
  LineElement,
  Legend,
  Tooltip,
  LineController,
  BarController,
  ArcElement
} from "chart.js";
import { colors, Spinner } from "../services/Common";
import Plot from "react-plotly.js";

ChartJS.register(
  LinearScale,
  CategoryScale,
  BarElement,
  PointElement,
  LineElement,
  Legend,
  Tooltip,
  LineController,
  BarController,
  ArcElement
);
function NewViewerFlightChart({
  flightData,
  noAssesment,
  error,
  isModel,
  isMobile,
  isMobileLandscape,
  isTablet,
  flightDataLoaded,
  navbarButtons,
  barExpand
}) {
  // const isLandScape = useMediaQuery({ maxHeight: 440 });
  // const minHeight = useMediaQuery({ minHeight: 440 });
  // const iphoneSE = useMediaQuery({ maxWidth: 375 });
  // const iphoneSELandScape = useMediaQuery({ maxHeight: 375 });
  const dateArray = flightData?.flightChartData;
  const allDates = new Set();
  const data = {
    labels: [], //array to store the dates
    datasets: [] // array to store the treatmentNumbers
  };
  let x = [];
  //Create new object with treatment num and mean details
  const treatments = dateArray?.map((item) => ({
    treatmentNum: item.treatment_num,
    meanDetails: item.mean_details
  }));

  treatments?.forEach((item) => {
    item?.meanDetails.forEach((detail) => {
      allDates.add(detail?.date);
    });
  });

  treatments?.forEach((flight) => {
    const flightDates = new Set(flight?.meanDetails.map((item) => item?.date));
    allDates.forEach((date) => {
      if (!flightDates.has(date)) {
        flight.meanDetails.push({ date, mean_value: 0 });
      }
    });
  });

  treatments?.forEach((flight) => {
    flight?.meanDetails?.sort((a, b) => new Date(a?.date) - new Date(b?.date));
  });

  //sorted by treatment num
  const sortedData = treatments?.sort((a, b) => a?.treatmentNum - b?.treatmentNum);
  sortedData?.sort().forEach((treatment) => {
    const dataset = {
      label: `${treatment.treatmentNum}`,
      data: [],
      fill: false,
      borderColor:
        treatment?.treatmentNum <= 30
          ? colors[treatment.treatmentNum]
          : colors[treatment.treatmentNum % 30],
      pointBorderColor:
        treatment?.treatmentNum <= 30
          ? colors[treatment.treatmentNum]
          : colors[treatment.treatmentNum % 30],
      pointBackgroundColor:
        treatment?.treatmentNum <= 30
          ? colors[treatment.treatmentNum]
          : colors[treatment.treatmentNum % 30]
    };
    //On each meandetails looped and if created unique array of dates and array of mean details with respective dates

    treatment?.meanDetails
      .sort(function (a, b) {
        return new Date(a) - new Date(b);
      })
      .forEach((detail) => {
        const index = data?.labels.findIndex((object) => object === detail?.date);
        if (index === -1) {
          data?.labels.push(detail?.date);
        }
          dataset?.data.push(detail?.mean_value);
          x.push(detail?.mean_value);
      });
    data?.datasets.push(dataset);
  });

  data?.labels.sort(function (a, b) {
    return new Date(a) - new Date(b);
  });

  const PlotData = treatments
    ?.sort((a, b) => new Date(a?.date) - new Date(b?.date))
    .map((item) => ({
      x: item?.meanDetails.map((details) => details?.date),
      y: item?.meanDetails.map((details) => parseFloat(details?.mean_value).toFixed(2)),
      type: "scatter",
      mode: "lines+markers",
      name: item?.treatmentNum,
      line: {
        width: 2.5
      },
      marker: {
        size: 8,
        color:
          item?.treatmentNum <= 30 ? colors[item?.treatmentNum] : colors[item?.treatmentNum % 30]
      }
    }));

  const normalMobileLeft = isMobile &&  !isMobileLandscape;
  const mobileLandscapeLeft = isMobileLandscape && !isMobile;

  // const iphoneSELeft = iphoneSE ? "iphoneSEFlight" : iphoneSELandScape && "iphoneSELandScape";
  const isMobileLandscapeLeft = mobileLandscapeLeft ? "landscapeFlightChart" :"";
  const left = normalMobileLeft ? "flightChart" : isMobileLandscapeLeft;
  const flightChartMobileWidth =
    (isMobile || isMobileLandscape || isTablet ) && !isModel;
  const flightDesktopWidth = !isModel && !(isMobile || isMobileLandscape || isTablet);
  const desktopWidth = flightDesktopWidth ? "not-model-not-mobile-width" : "defualt-width";
  const mobileWidth = flightChartMobileWidth ? "not-model-but-mobile-width" : desktopWidth;
  const width = isModel ? "model-width"  : navbarButtons?.length === 1 || barExpand ? "100vw" : mobileWidth;

  const isMobileHeight = isMobile ? "mobile-height" : "default-height";
  const isMobileLandscapeHeight = isMobileLandscape ? "mobile-landscape-height" : isMobileHeight;
  // const iphoneSELandScapeHeight = iphoneSELandScape
  //   ? "mobile-iphoneSE-height"
  //   : isMobileLandscapeHeight;
  const isModelHeght = isModel ? "model-height" : isMobileLandscapeHeight;

  const height = isMobile ? "default-height" : isModelHeght;

  const errorConditon = noAssesment || error || x.length === 0;
  const isModalAndTabAR = isModel || isTablet || !(isMobile || isMobileLandscape) ? true : false;
  const isMobileAR = isMobile ? false : isModalAndTabAR;
  const autosizeAndResponsive = isMobileLandscape ? true : isMobileAR;
  const showAll = isModel || isTablet ||  isMobileLandscape || !isMobile;
  const showallLegends = showAll ? true : false;
  const flightShowLegend = isMobile ? false : showallLegends;
  const tabletMB = isTablet ? 220 : 200;
  const isLanscapeMB = isMobileLandscape ? 160 : tabletMB;
  const isModelMB = isModel ? 130 : isLanscapeMB;
  const isMobileLandscapeMB = isMobileLandscape ? 160 : isModelMB;
  const isMobileMB = isMobile ? 160 : isMobileLandscapeMB;
  const flightMarginBottom = isMobile ? 160 : isMobileMB;
  const tabletML = isMobileLandscape ? 70 : 75;
  const flightMarginLeft = isMobile ? 45 : tabletML;
  const sortedDates = data?.labels.sort(function (a, b) {
    return new Date(a) - new Date(b);
  });

  const errorMessages =
    noAssesment || x.length === 0
      ? "Assessment data is unavailable"
      : "No Treatment Data available";

  useEffect(() => {
    const temp = document.querySelector(".scrollbox");
    if (temp) {
      temp.addEventListener("wheel", (e) => {
        e.preventDefault();
      });
    }
  });

  return flightDataLoaded ? (
    <div className="chart-loader-container">
      <Spinner className="chart-spinner" />
    </div>
  ) : errorConditon ? (
    <div 
    // style={{ height: minHeight ? "130px" : "280px" }}
    >
      <span className="error-message">
        <strong>{errorMessages}</strong>
      </span>
    </div>
  ) : (
    <div className="chart-container">
      <div className={`overflow-hidden overflow-x-hidden ${left} ${width} ${height}`}>
        {data?.labels.length > 1 && (
          <>
            <span className="chart-title light-grey-color">{flightData?.name?.split("v:")[0]}</span>
            <Plot
              style={{ cursor: "pointer" }}
              data={PlotData}
              layout={{
                autosize: autosizeAndResponsive,
                margin: {
                  t: 50,
                  b: flightMarginBottom,
                  l: flightMarginLeft,
                  r: 30
                },
                showlegend: flightShowLegend,
                legend:
                  isMobileLandscape 
                    ? {
                        orientation: "h",
                        y: 1.5,
                        usePointStyle: true
                      }
                    : {
                        usePointStyle: true
                      },

                xaxis: isMobile || isMobileLandscape
                  ? {
                      type: "date",
                      linecolor: "#E8E8E8",
                      tickvals: sortedDates,
                      tickformat: "%Y-%m-%d"
                    }
                  : {
                      type: "date",
                      linecolor: "#E8E8E8",
                      tickvals: sortedDates,
                      tickformat: "%Y-%m-%d",

                      zeroline: false,
                      tickfont: {
                        size: 10
                      },
                      title: {
                        text: "Dates"
                      },
                      titlefont: {
                        family: "Helvetica",
                        size: 12,
                        color: "#AAA9A9"
                      }
                    },
                yaxis: {
                  type: "linear",
                  tickfont: {
                    size: 10
                  },
                  title: {
                    text: "Average Value"
                  },
                  titlefont: {
                    family: "Helvetica",
                    size: 12,
                    color: "#AAA9A9"
                  }
                },
                annotations: isMobile ? [
                  {
                    x: 0.2,
                    y: -0.3,
                    text: "Dates",
                    showarrow: false,
                    xref: "paper",
                    yref: "paper",
                    xanchor: "left",
                    yanchor: "bottom",
                    font: { size: 12, family: "Helvetica", color: "#AAA9A9" }
                  }
                ] : isMobileLandscape && [
                  {
                    x: 0.45,
                    y: -0.5,
                    text: "Dates",
                    showarrow: false,
                    xref: "paper",
                    yref: "paper",
                    xanchor: "left",
                    yanchor: "bottom",
                    font: { size: 12, family: "Helvetica", color: "#AAA9A9" }
                  }
                ],
                responsive: autosizeAndResponsive
              }}
              config={{
                staticPlot: false,
                displaylogo: false,
                responsive: autosizeAndResponsive,

                animation: false,
                modeBarButtonsToRemove: [
                  "pan2d",
                  "select2d",
                  "autoScale2d",
                  "hoverClosestCartesian",
                  "zoom2d",
                  "lasso2d"
                ]
              }}
            />
          </>
        )}
        {data?.labels.length === 1 && (
          <span className="flight-one-date-error">
            <strong>Treatment information is unavailable as it has only one date</strong>
          </span>
        )}
      </div>
    </div>
  );
}

NewViewerFlightChart.propTypes = {
  flightData: propTypes.any,
  noAssesment: propTypes.any,
  error: propTypes.any,
  isModel: propTypes.any,
  isMobileLandscape: propTypes.any,
  isMobile: propTypes.any,
  isTablet: propTypes.any,
  flightDataLoaded: propTypes.any,
  navbarButtons: propTypes.any,
  barExpand: propTypes.any
};

export default NewViewerFlightChart;
