import { useMediaQuery } from "react-responsive";
import { useState, useRef, useEffect } from "react";
import { Button } from "react-bootstrap";
import syngentaLogo from "../assests/Styles/assets/logo.png";
import mobileLogo from "../assests/Styles/assets/mobileLogo.png";
import logoutIcon from "../assests/Styles/assets/logoutIcon.svg";
import { useNavigate } from "react-router-dom";
import { Bookmark, CircleFill, BookmarkFill } from "react-bootstrap-icons";
import MapYearandSearch from "./MapYearandSearch";
import "../assests/Styles/mapviewer.css";
import propTypes from "prop-types";
import LandingPageToggleSwitch from "./LandingPageToggleSwitch";

export default function MapMobileTopBar({
  setYearLoader,
  setSelectedResult,
  setTrialsList,
  setShowWelcomeText,
  refinedSearch,
  setYearSelection,
  setRefinedSearch,
  isOn,
  setIsOn,
  myTrials,
  setMyTrials,
  setTopBookmarkTrail,
  topBookmarkTrail,
  selectedOption,
  setSelectedOption,
  yearList,
  searchText,
  setSearchText,
  showAutoSuggestionPopup,
  trialsByYear,
  setShowAutoSuggestionPopup,
  popMarker,
  myTrialToggleButton,
  setRefinedSearchTrialslist,
  setSelectedItems,
  setRefinedSearchText,
  refinedAccordions,
  setIsFilterByDroneAssessment,
  setIsFilterByOrthoMosaic
}) {
  const name = sessionStorage.getItem("userName");
  const pro = name?.split(".");
  const [profileClick, setProfileClick] = useState(false);
  const dropdownRef = useRef(null);
  const profileIconRef = useRef(null);
  let navigate = useNavigate();
  const isMobile = useMediaQuery({ maxWidth: 576 });
  const isMobileLandscape = useMediaQuery({ maxHeight: 430 });
  const iphoneSELandScape = useMediaQuery({ maxHeight: 375 });
  const extraSmall = useMediaQuery({ maxWidth: 360 });

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (
        dropdownRef.current &&
        !dropdownRef.current.contains(event.target) &&
        !profileIconRef?.current?.contains(event.target)
      ) {
        setProfileClick(false);
      }
    };
    window.addEventListener("mousedown", handleClickOutside);
    return () => {
      window.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  const routeChange = () => {
    let path = "/home/logout";
    navigate(path);
  };

  const handleButtonClick = () => {
    setProfileClick(!profileClick);
  };

  const myTrialChange = () => {
    setIsOn(!isOn);
    setMyTrials(!myTrials);
  };

  const onBookmarkClick = () => {
    setTopBookmarkTrail(!topBookmarkTrail);
    setSelectedResult("");
  };

  return (
    <div>
      <div className="row">
        <div
          className="col-1 d-flex align-items-center"
          style={{
            borderRight: " ",
            padding: isMobile ? "5px 15px" : "10px"
          }}>
          <a className="logo" href="/app" id="viewer-link">
            <div className="align-items-center">
              <img
                src={isMobileLandscape ? mobileLogo : syngentaLogo}
                href="/app"
                alt="syngenta"
                className="map-syngenta-logo"
              />
            </div>
          </a>
        </div>
        <div
          className={`${
            isMobile ? "col-7" : iphoneSELandScape ? "col-3" : isMobileLandscape ? "col-3" : ""
          } d-flex align-items-center justify-content-center`}>
          {myTrialToggleButton && (
            <div className="d-flex align-items-center" style={{ padding: "10px 10px 10px 20px" }}>
              <span style={{ color: "white", width: "max-content" }}>My trials</span>
              <Button
                className="toggle-switch-new-landing-mobile"
                variant={isOn ? "primary" : "secondary"}
                onClick={myTrialChange}>
                {isOn ? (
                  <>
                    <span style={{ paddingRight: "10px" }}>ON</span>
                    <span className="circle">
                      <CircleFill width={20} height={20} />
                    </span>
                  </>
                ) : (
                  <>
                    <span className="circle">
                      <CircleFill width={20} height={20} />
                    </span>
                    <span className="circle" style={{ paddingLeft: "10px" }}>
                      OFF
                    </span>
                  </>
                )}
              </Button>
            </div>
          )}
        </div>
        {isMobileLandscape && (
          <div
            className={"col-6 d-flex align-items-center justify-content-center"}
            style={{ marginLeft: iphoneSELandScape ? "5px" : "" }}>
            <MapYearandSearch
              setYearLoader={setYearLoader}
              setSelectedResult={setSelectedResult}
              setTrialsList={setTrialsList}
              popMarker={popMarker}
              setShowWelcomeText={setShowWelcomeText}
              setYearSelection={setYearSelection}
              selectedOption={selectedOption}
              setSelectedOption={setSelectedOption}
              setRefinedSearch={setRefinedSearch}
              yearList={yearList}
              searchText={searchText}
              setSearchText={setSearchText}
              showAutoSuggestionPopup={showAutoSuggestionPopup}
              trialsByYear={trialsByYear}
              setShowAutoSuggestionPopup={setShowAutoSuggestionPopup}
              setTopBookmarkTrail={setTopBookmarkTrail}
              setRefinedSearchTrialslist={setRefinedSearchTrialslist}
              setIsOn={setIsOn}
              setSelectedItems={setSelectedItems}
              setRefinedSearchText={setRefinedSearchText}
              refinedAccordions={refinedAccordions}
              setIsFilterByDroneAssessment={setIsFilterByDroneAssessment}
              setIsFilterByOrthoMosaic={setIsFilterByOrthoMosaic}
              setMyTrials={setMyTrials}
            />
          </div>
        )}

        <div
          className={
            isMobile
              ? "col-2 d-flex justify-content-center align-items-center"
              : "col-1 d-flex justify-content-center align-items-center"
          }
          style={{
            left: isMobileLandscape ? "3%" : "6%",
            backgroundColor: "transparent",
            border: "none"
          }}
          role="button"
          tabIndex="0"
          onFocus={() => {}}
          onClick={onBookmarkClick}
          onKeyDown={() => {}}>
          {topBookmarkTrail ? (
            <BookmarkFill height={24} width={24} color="white" />
          ) : (
            <Bookmark height={24} width={24} color="white" />
          )}
        </div>

        <div
          className={
            isMobile
              ? "col-2 d-flex justify-content-center align-items-center"
              : "col-1 d-flex justify-content-center align-items-center"
          }
          style={{
            right: isMobile ? "2%" : iphoneSELandScape ? "1%" : isMobileLandscape ? "2%" : ""
          }}>
          <button
            className="ml-auto map-profile-icon"
            ref={profileIconRef}
            onClick={handleButtonClick}>
            {pro && pro.length > 1
              ? pro[0].charAt(0).toUpperCase() + pro[1].charAt(0).toUpperCase()
              : pro[0].charAt(0).toUpperCase()}
          </button>
          {profileClick && (
            <div
              ref={dropdownRef}
              style={{
                position: "absolute",
                right: extraSmall ? "0px" : isMobile ? "5%" : "6%",
                top: isMobile || isMobileLandscape ? "80px" : "60px",
                backgroundColor: "#0D1A40",
                zIndex: "9999",
                minWidth: isMobile ? "101vw" : "300px"
              }}>
              <ul className="list-group">
                <li className="list-group-item">{name}</li>
                <li className="list-group-item">
                  <LandingPageToggleSwitch isNewLandingPage />
                </li>

                <li className="list-group-item d-flex justify-content-center">
                  <button className="logout-Mobile" onClick={routeChange}>
                    <span>
                      <img
                        className="logout-icon"
                        alt=""
                        src={logoutIcon}
                        height={16}
                        width={16}
                        style={{ marginBottom: "5px" }}
                      />{" "}
                      LOGOUT
                    </span>
                  </button>
                </li>
              </ul>
            </div>
          )}
        </div>
      </div>
      {isMobile && (
        <div className="mobile-map-topbar">
          {isMobile && (
            <MapYearandSearch
              setYearLoader={setYearLoader}
              setSelectedResult={setSelectedResult}
              setTrialsList={setTrialsList}
              setShowWelcomeText={setShowWelcomeText}
              refinedSearch={refinedSearch}
              setYearSelection={setYearSelection}
              selectedOption={selectedOption}
              setSelectedOption={setSelectedOption}
              yearList={yearList}
              searchText={searchText}
              setSearchText={setSearchText}
              showAutoSuggestionPopup={showAutoSuggestionPopup}
              trialsByYear={trialsByYear}
              setRefinedSearch={setRefinedSearch}
              setShowAutoSuggestionPopup={setShowAutoSuggestionPopup}
              setTopBookmarkTrail={setTopBookmarkTrail}
              popMarker={popMarker}
              setRefinedSearchTrialslist={setRefinedSearchTrialslist}
              setIsOn={setIsOn}
              setSelectedItems={setSelectedItems}
              setRefinedSearchText={setRefinedSearchText}
              refinedAccordions={refinedAccordions}
              setIsFilterByDroneAssessment={setIsFilterByDroneAssessment}
              setIsFilterByOrthoMosaic={setIsFilterByOrthoMosaic}
              setMyTrials={setMyTrials}
            />
          )}
        </div>
      )}
    </div>
  );
}
MapMobileTopBar.propTypes = {
  setYearLoader: propTypes.any,
  setSelectedResult: propTypes.any,
  setTrialsList: propTypes.any,
  setShowWelcomeText: propTypes.any,
  refinedSearch: propTypes.bool,
  setYearSelection: propTypes.any,
  isOn: propTypes.bool,
  setIsOn: propTypes.bool,
  setMyTrials: propTypes.bool,
  myTrials: propTypes.bool,
  setTopBookmarkTrail: propTypes.bool,
  topBookmarkTrail: propTypes.bool,
  selectedOption: propTypes.any,
  setSelectedOption: propTypes.any,
  yearList: propTypes.any,
  searchText: propTypes.any,
  setSearchText: propTypes.any,
  showAutoSuggestionPopup: propTypes.any,
  trialsByYear: propTypes.any,
  setShowAutoSuggestionPopup: propTypes.any,
  popMarker: propTypes.func,
  myTrialToggleButton: propTypes.bool,
  setRefinedSearch: propTypes.bool,
  setRefinedSearchTrialslist: propTypes.any,
  setSelectedItems: propTypes.any,
  setRefinedSearchText: propTypes.any,
  refinedAccordions: propTypes.any,
  setIsFilterByDroneAssessment: propTypes.any,
  setIsFilterByOrthoMosaic: propTypes.any,
};
