import { useState } from "react";
import { ChevronRight, ChevronDown, ChevronUp, X, InfoCircleFill } from "react-bootstrap-icons";
import PropTypes from "prop-types";
import "../assests/Styles/newviewer.css";
import { propTypes } from "react-bootstrap/esm/Image";
import { isMobile } from "react-device-detect";
import AssessmentContainerMobile from "./AssessmentContainerMobile";
import { Button, Tooltip, OverlayTrigger } from "react-bootstrap";

const HamburgerMenu = ({
  setHamburgerOpen,
  trialType,
  selectedOption,
  trailArray,
  onSelect,
  colorMapConst,
  hamburgerButtonRef,
  customMobilePortrait,
  setAssessmentContainerVisible,
  assessmentContainerVisible,
  assessmentBy,
  setAssessmentBy,
  isTablet,
  customMobileLandscape,
  assessmentDatesAndTypes,
  assessmentsFilteredByType,
  assessmentDateType,
  handleAssessmentSelect,
  setAssessmentDateType,
  setShowTreatment,
  setShowTime,
  setShowCompare,
  setShowTreatmentMT,
  setShowTimeMT,
  setShowCompareMT,
  setAssessmentOptionsVisible,
  tabletNavbarButton,
  setTabletNavbarButton,
  showTabletTooltip,
  setShowTabletTooltip,
  targetAssTabletRef,
  items,
  tooltipRef,
  tooltipRef1,
  setCloseTooltip
}) => {
  const [isAccordionOpen, setIsAccordionOpen] = useState(false);

  const toggleAccordion = () => {
    setIsAccordionOpen(!isAccordionOpen);
    if (!isAccordionOpen) {
      console.log("hello");
    }
  };
  const handleCloseClick = () => {
    setHamburgerOpen(false);
  };
  const handleButtonClick = (button) => {
    setTabletNavbarButton(button);
    setHamburgerOpen(false);
  };

  const renderAssessmentTooltip = (props) => {
    return (
      <Tooltip
        id="tooltip"
        ref={tooltipRef}
        {...props}
        style={{ position: "absolute", right: customMobileLandscape ? "0px" : "", top: "54px" }}
        className="newviewer-assessment-mobile-buttons-tooltip">
        <div className="d-flex align-items-center justify-content-between ">
          <span style={{ marginLeft: "8px", marginRight: "15px" }}>
            <InfoCircleFill height={20} width={20} />
          </span>
          <span
            className="mr-4 font-size-small font-weight-700"
            style={{ fontFamily: "Helvetica" }}>
            Please select a trial to enable Assessments
          </span>
          {(customMobilePortrait || customMobileLandscape) && (
            <span style={{ position: "absolute", right: "5px" }}>
              <X
                height={24}
                width={24}
                onClick={() => {
                  setHamburgerOpen(true);
                  setCloseTooltip(false);
                }}
              />
            </span>
          )}
        </div>
      </Tooltip>
    );
  };

  const isDisabled = !selectedOption || ["All trials", "No trials"].includes(selectedOption);

  const renderMobileTooltip = (name, props) => {
    return (
      <Tooltip
        id="tooltip1"
        ref={tooltipRef1}
        {...props}
        className={
          customMobilePortrait ? "newviewer-mobile-buttons-tooltip" : "newviewer-mobile-landscape-buttons-tooltip"
        }>
        <div className="d-flex align-items-center justify-content-between ">
          <span style={{ marginLeft: "8px", marginRight: "15px" }}>
            <InfoCircleFill height={20} width={20} />
          </span>
          <span
            style={{
              fontFamily: "Helvetica",
              fontSize: "11px",
              marginLeft:
                name === "Treatment Labels" ? "10px" : name === "Environmental" ? "15px" : ""
            }}
            className="mr-4 font-size-small font-weight-700">
            {name === "Environmental"
              ? "Please select a trial to enable Environmental"
              : name === "Charts"
                ? "Please select at least one assessment to enable Charts"
                : name === "Treatment Labels"
                  ? "Please select a trial to enable Treatment Labels"
                  : ""}
          </span>
          <span
            style={{
              position: "absolute",
              right: "5px",
              marginLeft:
                name === "Treatment Labels" ? "10px" : name === "Environmental" ? "14px" : ""
            }}>
            <X
              width={24}
              height={24}
              onClick={() => {
                setHamburgerOpen(true);
                setCloseTooltip(false);
              }}
            />
          </span>
        </div>
      </Tooltip>
    );
  };

  return (
    <div
      style={{
        position: "absolute",
        zIndex: 9999,
        backgroundColor: "white",
        width: customMobilePortrait ? "100%" : "430px",
        right: "0px",
        height: "calc(100vh - 60px)",
        overflowY: assessmentContainerVisible ? "hidden" : "auto"
      }}
      ref={hamburgerButtonRef}>
      <div className="selector-menu">
        <span className="selectors-text"> Selections</span>
        <span style={{ position: "absolute", zIndex: 100, right: "5px" }}>
          <X onClick={handleCloseClick} alt="" height={24} width={24} />
        </span>
      </div>

      <div style={{ borderBottom: "1px solid #ededed" }}>
        <div className="hamburger-accordion-header" onClick={toggleAccordion}>
          <span className="trial-text">Trials</span>
          {isAccordionOpen ? <ChevronUp color="#0D1A40;" /> : <ChevronDown color="#0D1A40;" />}
        </div>
        {isAccordionOpen && (
          <div className="accordion-content">
            <div className={isMobile ? "mobileportrait-traildropdown-listitem" : ""}>
              {trailArray?.map((option) =>
                trialType == "manual" &&
                  (option === "All trials" || option === "No trials") ? null : (
                  <div className="p-1" key={option}>
                    <label htmlFor={`radio-${option}`} className="d-flex align-items-center">
                      <input
                        type="radio"
                        name="options"
                        checked={selectedOption === option}
                        onChange={() => trailArray?.length > 1 && onSelect(option)}
                        id={`radio-${option}`}
                        value={option}
                      />
                      <span htmlFor={`radio-${option}`} className="traildropdown-options">
                        {option}
                      </span>
                      {option !== "All trials" && option !== "No trials" && (
                        <span
                          className="color-square"
                          style={{
                            background:
                              trialType === "manual" ? "#1b74df" : colorMapConst?.[option] || "#fff"
                          }}></span>
                      )}
                    </label>
                  </div>
                )
              )}
            </div>
          </div>
        )}
      </div>
      <>
        <OverlayTrigger
          placement="top"
          trigger={["hover", "click"]}
          overlay={
            !selectedOption || ["All trials", "No trials"].includes(selectedOption) ? (
              renderAssessmentTooltip
            ) : (
              <></>
            )
          }>
          <div
            onClick={() =>
              !isDisabled && setAssessmentContainerVisible(!assessmentContainerVisible)
            }
            style={{ opacity: isDisabled ? 0.5 : 1, borderBottom: "1px solid #ededed" }}>
            <Button
              className="mobile-assessments-button"
              disabled={!selectedOption || ["All trials", "No trials"].includes(selectedOption)}
              style={{
                opacity: selectedOption === "All trials" || selectedOption === "No trials" ? 0.5 : 1
              }}
              onClick={() => {
                setAssessmentContainerVisible(!assessmentContainerVisible),
                  setIsAccordionOpen(false);
              }}>
              <span>
                Assessments
                {assessmentDateType.length > 0 && (
                  <span
                    className="pl-2"
                    style={{
                      fontSize: "12px",
                      fontWeight: "600"
                    }}>
                    {assessmentDateType.length}
                  </span>
                )}
              </span>
              <span style={{ float: "right" }}>
                <ChevronRight height={16} width={16} />
              </span>
            </Button>
          </div>
        </OverlayTrigger>
        {assessmentContainerVisible && (
          <div
            className={
              customMobilePortrait
                ? "assessment-mobile-container-portrait"
                : "assessment-mobile-container-landscape"
            }>
            <div className={`${isTablet && "text-center"}`}>
              <AssessmentContainerMobile
                assessmentBy={assessmentBy}
                setAssessmentBy={setAssessmentBy}
                isTablet={isTablet}
                customMobileLandscape={customMobileLandscape}
                assessmentDatesAndTypes={assessmentDatesAndTypes}
                assessmentsFilteredByType={assessmentsFilteredByType}
                assessmentDateType={assessmentDateType}
                handleAssessmentSelect={handleAssessmentSelect}
                setAssessmentDateType={setAssessmentDateType}
                setShowTreatment={setShowTreatment}
                setShowTime={setShowTime}
                setShowCompare={setShowCompare}
                setShowTreatmentMT={setShowTreatmentMT}
                setShowTimeMT={setShowTimeMT}
                setShowCompareMT={setShowCompareMT}
                setAssessmentOptionsVisible={setAssessmentOptionsVisible}
                setHamburgerOpen={setHamburgerOpen}
                setAssessmentContainerVisible={setAssessmentContainerVisible}
                assessmentContainerVisible={assessmentContainerVisible}
                customMobilePortrait={customMobilePortrait}
                showTabletTooltip={showTabletTooltip}
                setShowTabletTooltip={setShowTabletTooltip}
                targetAssTabletRef={targetAssTabletRef}
              />
            </div>
          </div>
        )}
      </>
      <div className="mobile-trial-assessment-button p-3">
        <div className="pb-4" style={{ display: "flex", flexWrap: "wrap", rowGap: "15px" }}>
          <div>
            <Button
              style={{
                backgroundColor: tabletNavbarButton === "Drone" ? "#E08330" : "#F1F2F3",
                color: tabletNavbarButton === "Drone" ? "#ffffff" : "#0D1A40",
                marginRight: "10px"
              }}
              onClick={() => {
                setTabletNavbarButton("Drone");
                setHamburgerOpen(false);
              }}
              className="viewer-bar-buttons ml-1">
              Drone/Layouts
            </Button>
          </div>
          {items?.map((item) => (
            <>
              <OverlayTrigger
                placement="top"
                trigger={["hover", "click"]}
                overlay={
                  !selectedOption ||
                    (["All trials", "No trials"].includes(selectedOption) &&
                      item === "Treatment Labels") ? (
                    renderMobileTooltip("Treatment Labels")
                  ) : !selectedOption ||
                    (["All trials", "No trials"].includes(selectedOption) &&
                      item === "Environmental") ? (
                    renderMobileTooltip("Environmental")
                  ) : !selectedOption ||
                    (["All trials", "No trials"].includes(selectedOption) && item === "Charts") ||
                    (selectedOption && assessmentDateType.length === 0 && item === "Charts") ? (
                    renderMobileTooltip("Charts")
                  ) : (
                    <></>
                  )
                }>
                <Button
                  style={{
                    backgroundColor: tabletNavbarButton === item ? "#E08330" : "#F1F2F3",
                    color: tabletNavbarButton === item ? "#ffffff" : "#0D1A40",
                  }}

                  className="viewer-bar-buttons ml-1"
                  onClick={() => handleButtonClick(item)}
                  disabled={
                    (item === "Charts" && assessmentDateType.length === 0) ||
                      (item === "Treatment Labels" &&
                        (!selectedOption ||
                          selectedOption === "All trials" ||
                          selectedOption === "No trials")) ||
                      (item === "Environmental" &&
                        (!selectedOption ||
                          selectedOption === "All trials" ||
                          selectedOption === "No trials"))
                      ? true
                      : false
                  }>
                  <span>{item}</span>
                </Button>
              </OverlayTrigger>
            </>
          ))}
        </div>
      </div>
    </div>
  );
};
HamburgerMenu.propTypes = {
  hamburgerOpen: propTypes.any,
  isEditViewer: PropTypes.any,
  isDisabled: PropTypes.any,
  trialType: PropTypes.any,
  overlayVectors: propTypes.any,
  hamburgerRef: propTypes.any,
  setHamburgerOpen: propTypes.any,
  navbarButtons: propTypes.any,
  handleTopButtonClick: propTypes.any,
  isImgAvailable: propTypes.any,
  selectedOption: propTypes.any,
  trailArray: propTypes.any,
  onSelect: propTypes.any,
  colorMapConst: propTypes.any,
  hamburgerButtonRef: propTypes.any,
  customMobilePortrait: propTypes.any,
  setAssessmentContainerVisible: propTypes.any,
  assessmentContainerVisible: propTypes.any,
  assessmentBy: propTypes.any,
  setAssessmentBy: propTypes.any,
  isTablet: propTypes.any,
  customMobileLandscape: propTypes.any,
  assessmentDatesAndTypes: propTypes.any,
  assessmentsFilteredByType: propTypes.any,
  assessmentDateType: propTypes.any,
  handleAssessmentSelect: propTypes.any,
  setAssessmentDateType: propTypes.any,
  setShowTreatment: propTypes.any,
  setShowTime: propTypes.any,
  setShowCompare: propTypes.any,
  setShowTreatmentMT: propTypes.any,
  setShowTimeMT: propTypes.any,
  setShowCompareMT: propTypes.any,
  setAssessmentOptionsVisible: propTypes.any,
  tabletNavbarButton: propTypes.any,
  setTabletNavbarButton: propTypes.any,
  showTabletTooltip: propTypes.any,
  setShowTabletTooltip: propTypes.any,
  targetAssTabletRef: propTypes.any,
  items: propTypes.any,
  tooltipRef: propTypes.any,
  tooltipRef1: propTypes.any,
  setCloseTooltip: propTypes.any
};
export default HamburgerMenu;
