import { useState, useEffect, useRef } from "react";
import { useMediaQuery } from "react-responsive";
import { Dropdown } from "react-bootstrap";
import { Search, ChevronDown, XLg, EmojiFrown } from "react-bootstrap-icons";
import "../assests/Styles/mapviewer.css";
import { getSearchAutoSuggestion, getTrialsList } from "../services/API";
import propTypes from "prop-types";
import "../assests/Styles/common.css";

function debounce(func, timeout = 300) {
  let timer;
  return (...args) => {
    clearTimeout(timer);
    timer = setTimeout(() => {
      func.apply(this, args);
    }, timeout);
  };
}
let selectedOptionConst = "";
export default function YearAndSearch({
  setYearLoader,
  setSelectedResult,
  setRefinedSearch,
  setTrialsList,
  setShowWelcomeText,
  refinedSearch,
  setYearSelection,
  selectedOption,
  setSelectedOption,
  yearList,
  searchText,
  setSearchText,
  showAutoSuggestionPopup,
  setShowAutoSuggestionPopup,
  setTopBookmarkTrail,
  popMarker,
  setRefinedSearchTrialslist,
  setIsOn,
  setSelectedItems,
  setRefinedSearchText,
  refinedAccordions,
  setIsFilterByDroneAssessment,
  setIsFilterByOrthoMosaic,
  setMyTrials,
}) {
  const [trialOpen, setTrialOpen] = useState(false);
  const isMobile = useMediaQuery({ maxWidth: 576 });
  const isSmallDeviceLandScape = useMediaQuery({ maxHeight: 375 });
  const isSmallDevice = useMediaQuery({ minWidth: 355, maxWidth: 380 });
  const isMobileLandscape = useMediaQuery({ maxHeight: 430 });
  selectedOptionConst = selectedOption;

  const handleSelect = (option) => {
    setSelectedOption(option);
    setYearSelection(option);
    setSelectedResult("");
    setTrialsList([]);
    setIsOn(true);
  };
  const handleToggle = () => {
    setTrialOpen(!trialOpen);
  };

  const isTablet = useMediaQuery({ minWidth: 577, maxWidth: 869 });

  const [filteredData, setFilteredData] = useState({});
  const [searchLoader, setSearchLoader] = useState({});
  const autoSuggestionRef = useRef();
  const searchBarRef = useRef();

  useEffect(() => {
    if (searchText.length > 0) {
      setShowWelcomeText(false);
    }
  }, [searchText]);

  useEffect(() => {
    const handleClickOutsideFlightDates = (event) => {
      if (
        autoSuggestionRef.current &&
        !autoSuggestionRef.current.contains(event.target) &&
        !searchBarRef.current.contains(event.target)
      ) {
        setShowAutoSuggestionPopup(false);
      }
    };
    window.addEventListener("mousedown", handleClickOutsideFlightDates);
    return () => {
      window.removeEventListener("mousedown", handleClickOutsideFlightDates);
    };
  }, []);
  const handleChange = (value) => {
    if (value.length >= 4) {
      setSearchLoader(true);
      setShowAutoSuggestionPopup(true);
      const data = {
        value,
        year: parseInt(selectedOptionConst)
      };
      getSearchAutoSuggestion(
        data,
        (res) => {
          const filteredSearchData = {};
          const result = res?.results;
          if (result?.trial_owner?.length > 0) {
            const uniqueEmails = {};
            const filteredDataByEmail = result?.trial_owner.filter((item) => {
              if (item.email && !uniqueEmails[item.email.toLowerCase()]) {
                uniqueEmails[item.email.toLowerCase()] = true;
                return true;
              }
              return false;
            });

            filteredSearchData["TRIAL OWNER"] = filteredDataByEmail.map((owner) => {
              return {
                value: owner?.email?.toLowerCase(),
                text: owner?.name,
                type: "trialOwnerEmail"
              };
            });
          }
          if (result?.trial_id?.length > 0) {
            filteredSearchData["TRIAL NUMBER"] = result?.trial_id.map((value) => {
              return {
                value: value,
                text: value,
                type: "trialId"
              };
            });
          }
          if (result?.protocol_owner?.length > 0) {
            filteredSearchData["PROTOCOL OWNER"] = result?.protocol_owner
              .filter(({ email }) => email)
              .map((owner) => {
                return {
                  value: owner?.email,
                  text: owner?.name,
                  type: "protocolOwnerEmail"
                };
              });
          }
          if (result?.protocol_id?.length > 0) {
            filteredSearchData["PROTOCOL NUMBER"] = result?.protocol_id.map((value) => {
              return {
                value: value,
                text: value,
                type: "protocolId"
              };
            });
          }
          if (result?.crops?.length > 0) {
            filteredSearchData["CROP"] = result?.crops.map((crop) => {
              return {
                value: crop?.code,
                text: crop?.commonName,
                type: "cropId"
              };
            });
          }
          if (result?.pests?.length > 0) {
            filteredSearchData["TARGET"] = result?.pests.map((pest) => {
              return {
                value: pest?.code,
                text: pest?.scientificName,
                type: "pestId"
              };
            });
          }

          if (result?.country?.length > 0) {
            filteredSearchData["COUNTRY"] = result?.country.map((country) => {
              return {
                value: country?.country_name,
                text: country?.country_name,
                type: "countryName"
              };
            });
          }
          setFilteredData(filteredSearchData);
          setSearchLoader(false);
          setTopBookmarkTrail(false);
        },
        (err) => {
          console.log("err", err);
          setSearchLoader(false);
        }
      );
    } else {
      setSearchLoader(false);
      setShowAutoSuggestionPopup(false);
    }
  };

  const debouncedHandleChange = useRef(debounce(handleChange, 500));
  const getTrials = (data, text) => {
    setShowAutoSuggestionPopup(false);
    setYearLoader(true);
    data.year = parseInt(selectedOption);
    getTrialsList(
      data,
      (res) => {
        setRefinedSearchText(
          refinedAccordions.reduce((acc, category) => {
            acc[category.key] = "";
            return acc;
          }, {})
        );
        setIsFilterByDroneAssessment(false);
        setIsFilterByOrthoMosaic(false);
        setSelectedItems({});
        setRefinedSearch(false);
        setYearLoader(false);
        setTopBookmarkTrail(false);
        setTrialsList(res?.results);
        setRefinedSearchTrialslist(res?.results);
        popMarker();
        setSelectedResult(text);
        setMyTrials(false);
        setIsOn(false);
      },
      (err) => {
        setTrialsList([]);
        setYearLoader(false);
        console.log("err", err);
      }
    );
  };
  return (
    <div className="d-flex justify-content-center align-items-center">
      <div
        ref={searchBarRef}
        className={
          isSmallDevice
            ? "map-smallDevice-yearAndSearch"
            : isSmallDeviceLandScape
            ? "map-smalldevicelandscape-yearAndSearch"
            : isMobile
            ? "map-mobile-yearAndSearch"
            : isMobileLandscape
            ? "map-yearAndSearch-lanscape"
            : isTablet
            ? "map-yearAndSearch-tablet"
            : "map-yearAndSearch"
        }
        style={{ zIndex: refinedSearch && isMobile ? "0" : "998" }}>
        <div>
          <Dropdown
            onToggle={handleToggle}
            onSelect={handleSelect}
            className={`${trialOpen ? "dropdown" : "newDropdown"}`}>
            <Dropdown.Toggle className="map-year-dropdown" variant="light">
              <div className="d-flex justify-content-center align-items-center">
                <div style={{ textAlign: "left", lineHeight: "0.1px" }}>
                  <span style={{ fontSize: "14px", color: "#0D1A40" }}>{selectedOption}</span>
                  <span>
                    <ChevronDown
                      style={{
                        marginLeft: "20px",
                        width: "100%px",
                        height: "16px",
                        color: "#0D1A40"
                      }}
                    />
                  </span>
                </div>
              </div>
            </Dropdown.Toggle>
            <div className="custom-dropdown-menu">
              <Dropdown.Menu
                col-xs-12
                className="dropdown-menu-show"
                onChange={(e) => handleSelect(e.target.value)}>
                <div className="select-year">SELECT YEAR</div>
                {yearList
                  ?.sort(function (a, b) {
                    return b - a;
                  })
                  .map((option) => (
                    <Dropdown.Item
                      key={option}
                      eventKey={option}
                      value={option}
                      onClick={() => handleSelect(option)}
                      active={selectedOption === option}>
                      <div
                        className="option-bold"
                        style={{
                          marginLeft: "10px",
                          fontSize: "12px",
                          color: selectedOption == option ? "#E08330" : "#0D1A40",
                          fontWeight: selectedOption == option ? "bold" : "normal"
                        }}>
                        {option}
                      </div>
                    </Dropdown.Item>
                  ))}
              </Dropdown.Menu>
            </div>
          </Dropdown>
        </div>
        <div style={{ width: "356px", height: "48px", position: "sticky" }}>
          <input
            type="text"
            className="map-search"
            style={{
              width: "100%",
              height: "100%",
              borderTopRightRadius: "24px",
              borderBottomRightRadius: "24px",
              outline: "none",
              fontSize: "12px",
              color: "#0D1A40",
              border: "1px solid #F9F9F9",
              paddingLeft: "6px"
            }}
            placeholder={
              isMobile || isSmallDevice || isSmallDeviceLandScape
                ? "Search"
                : "Search for trial, protocol, people, crop and more..."
            }
            onChange={(e) => {
              setSearchText(e.target.value);
              debouncedHandleChange.current(e.target.value);
            }}
            value={searchText}
            // onFocus={() => {
            //   searchText.length >= 4 && setShowAutoSuggestionPopup(true);
            // }}
          />
          <span
            style={{
              position: "absolute",
              right: isMobile || isSmallDevice ? "8%" : "6%",
              top: "10px"
            }}>
            {!showAutoSuggestionPopup && !searchText ? (
              <Search color="#0D1A40" />
            ) : (
              <XLg
                role="button"
                color="#0D1A40"
                onClick={() => {
                  setShowAutoSuggestionPopup(false);
                  setSearchText("");
                }}
              />
            )}
          </span>
        </div>
      </div>
      {showAutoSuggestionPopup ? (
        <div
          ref={autoSuggestionRef}
          className={
            isSmallDevice
              ? "search-smallDevice-menu-list"
              : isMobile
              ? "search-mobile-menu-list"
              : isSmallDeviceLandScape
              ? "small-device-landscape-search-menu-list"
              : isMobileLandscape
              ? "search-landscape-menu-list"
              : isTablet
              ? "search-tablet-menu-list"
              : "search-menu-list"
          }>
          <div style={{ marginTop: "40px" }}>
            {searchLoader && (
              <div className="d-flex justify-content-center align-items-center p-3">
                <div className="spinner-border text-warning" role="status"></div>
                <span className="search-loading-text ml-2">Searching for results</span>
              </div>
            )}
            <div className="overflow-auto">
              {!searchLoader &&
                Object.keys(filteredData).map((key) => (
                  <>
                    <div key={key} className="d-flex ">
                      <div className="search-menu-heading">{key}</div>
                      <div className="search-menu-data">
                        <ol style={{ listStyleType: "none", marginBottom: 0 }}>
                          {filteredData[key].map((data, index) => (
                            <li
                              onClick={() =>
                                getTrials(
                                  {
                                    type: data.type,
                                    value: data.value,
                                    user_email: sessionStorage.getItem("userName")
                                  },
                                  data.text
                                )
                              }
                              style={{
                                cursor: "pointer"
                              }}
                              className={`${index !== 0 ? "pt-2" : ""}`}
                              key={data.text}>
                              {data.text}
                            </li>
                          ))}
                        </ol>
                      </div>
                    </div>
                    <hr />
                  </>
                ))}
            </div>
            {!searchLoader && Object.keys(filteredData || {}).length === 0 && (
              <div className="d-flex ml-3 align-items-center p-3 mb-3">
                <EmojiFrown color="#FF3F3F" />
                <span className="no-data-text ml-2">Sorry we could not find any results</span>
              </div>
            )}
          </div>
        </div>
      ) : null}
    </div>
  );
}
YearAndSearch.propTypes = {
  setYearLoader: propTypes.any,
  setSelectedResult: propTypes.any,
  setTrialsList: propTypes.any,
  setShowWelcomeText: propTypes.any,
  refinedSearch: propTypes.any,
  setYearSelection: propTypes.any,
  selectedOption: propTypes.any,
  setSelectedOption: propTypes.any,
  yearList: propTypes.any,
  searchText: propTypes.any,
  setSearchText: propTypes.any,
  showAutoSuggestionPopup: propTypes.any,
  trialsByYear: propTypes.any,
  setShowAutoSuggestionPopup: propTypes.any,
  setTopBookmarkTrail: propTypes.bool,
  setRefinedSearch: propTypes.bool,
  popMarker: propTypes.func,
  setRefinedSearchTrialslist: propTypes.any,
  setIsOn: propTypes.any,
  setSelectedItems: propTypes.any,
  setRefinedSearchText: propTypes.any,
  refinedAccordions: propTypes.any,
  setIsFilterByDroneAssessment: propTypes.any,
  setIsFilterByOrthoMosaic: propTypes.any,
  setMyTrials: propTypes.any
};
