import { EmojiSunglassesFill, EmojiSmileFill } from "react-bootstrap-icons";
import propTypes from "prop-types";
import { Button } from "react-bootstrap";
import { useMediaQuery } from "react-responsive";

function LandingPageToggleSwitch({ isNewLandingPage = false }) {
  const isMobile = useMediaQuery({ maxWidth: 576 });
  const isTablet = useMediaQuery({ minWidth: 577, maxWidth: 869 });
  const tabMaxWidth = useMediaQuery({ maxWidth: 1024 });
  return (
    <>
      {!isNewLandingPage ? (
        <Button
          onClick={() => {
            window.location.href = "/app";
          }}
          className={
            isMobile || isTablet || tabMaxWidth
              ? "map-toggle-switch-Viewer-MT landing-page-toggle-switch"
              : "map-toggle-switch-Viewer landing-page-toggle-switch"
          }>
          <span className="d-flex justify-content-center align-items-center">
            <span style={{ fontSize: 12, fontWeight: 600, marginRight: "6px" }}>New Version</span>
            <EmojiSunglassesFill width={20} height={20} />
          </span>
        </Button>
      ) : (
        <Button
          onClick={() => {
            window.location.href = "/app/home";
          }}
          className={
            isMobile || isTablet || tabMaxWidth
              ? "map-toggle-switch-Viewer-MT landing-page-toggle-switch"
              : "map-toggle-switch-Viewer landing-page-toggle-switch"
          }>
          <span>
            <EmojiSmileFill width={20} height={20} className="emoji-smile" />
            <span style={{ fontSize: 12, fontWeight: 600 }}>Old Version</span>
          </span>
        </Button>
      )}
    </>
  );
}

LandingPageToggleSwitch.propTypes = {
  isNewLandingPage: propTypes.bool
};

export default LandingPageToggleSwitch;
