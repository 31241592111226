import { EmojiSunglassesFill, EmojiSmileFill } from "react-bootstrap-icons";
import propTypes from "prop-types";
import { Button } from "react-bootstrap";
import { customTabletDimensions } from "../services/Common";

function ToggleSwitch({ trial, collectionId, trialType, customMobileLandscape, customMobilePortrait }) {
  let params = collectionId ? `?collection_id=${collectionId}&trial=${trial}` : `?trial=${trial}`;
  const marginLeft =
    location?.pathname.split("/")[2] === "oldviewer"
      ? "toggle-MT-oldViewer"
      : "toggle-MT-newViewer";
  return (
    <div>
      {location?.pathname.split("/")[2] === "oldviewer" ? (
        <Button
          onClick={() => {
            (window.location.href = `/app/viewer${params}`), "_blank";
          }}
          className={
           customMobilePortrait || customMobileLandscape || customTabletDimensions()
              ? `toggle-switch-Viewer-MT ${marginLeft}`
              : "toggle-switch-Viewer"
          }>
          <span className="d-flex justify-content-center align-items-center">
            <span style={{ marginRight: "6px" }}>New Version</span>{" "}
            <EmojiSunglassesFill width={20} height={20} />
          </span>
        </Button>
      ) : (
        location?.pathname.split("/")[2] === "viewer" &&
        trialType !== "manual" && (
          <Button
            onClick={() => {
              window.location.href = `/app/oldviewer${params}`;
            }}
            className={
              customMobilePortrait || customMobileLandscape || customTabletDimensions()
                ? `toggle-switch-Viewer-MT ${marginLeft}`
                : "toggle-switch-Viewer"
            }>
            <span>
              <EmojiSmileFill width={20} height={20} className="emoji-smile" /> Old Version
            </span>
          </Button>
        )
      )}
    </div>
  );
}

ToggleSwitch.propTypes = {
  trial: propTypes.any,
  collectionId: propTypes.any,
  trialType: propTypes.any,
  customMobileLandscape: propTypes.any,
  customMobilePortrait: propTypes.any,
};

export default ToggleSwitch;
