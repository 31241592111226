/* eslint-disable react/jsx-key */
/* eslint-disable react/display-name */
import React, { Component } from "react";
import PropTypes from "prop-types";
import {
  Box,
  CssBaseline,
  Divider,
  Drawer,
  List,
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  Toolbar,
  Typography,
  AppBar,
  Stack,
  CircularProgress,
  Grid,
  SvgIcon,
  Button,
  Modal,
  TextField,
  FormControlLabel,
  RadioGroup,
  Radio,
  Avatar,
  Menu,
  MenuItem,
  Tab,
  Tabs,
  FormControl,
  NativeSelect,
  InputBase,
  Tooltip
} from "@mui/material";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
import "@babel/polyfill";
import "whatwg-fetch";
import "../assests/Styles/viewer.css";
import "../assests/Styles/leaflet-measure.css";
import "../assests/Styles/leaflet.css";
import * as L from "leaflet";
import "../assests/Scripts/leaflet-measure";
import EditIcon from "@mui/icons-material/Edit";
import ZoomOutMapIcon from "@mui/icons-material/ZoomOutMap";
import VisibilityIcon from "@mui/icons-material/Visibility";
import VisibilityOffIcon from "@mui/icons-material/VisibilityOff";
import withRouter from "./withRouter";
import BarChart from "./BarChart";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
import { ReactComponent as HeatmapIconOff } from "../app/icons/heatmap-icon-off.svg";
import { ReactComponent as HeatmapIconOn } from "../app/icons/heatmap-icon-on.svg";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";
import { styled } from "@mui/system";
import Backdrop from "@mui/material/Backdrop";
import "leaflet-rotate";
import triaLogo from "../../src/assests/Styles/assets/trailIcon.png";
import TrialSummary from "./TrialSummary";
import syngentaLogo from "../app/whitelogo.png";
import mobileLogo from "../assests/Styles/assets/mobile1.png";
import MyForm from "./formspree";
import {
  getMeasurmentSets,
  getCollectionResponse,
  getRasterLayersAPICall,
  getRatingDescriptions,
  viewCollectionDetailsAPI,
  loginSyt
} from "../services/API";
import ToggleSwitch from "./ToggleSwitch";

//Used for tooltip position
window.assessmentsCount = 0;
window.eyeOpenClosed = false;
window.isHeatMapActive = false;

const BootstrapInput = styled(InputBase)(({ theme }) => ({
  "label + &": {
    marginTop: theme.spacing(3)
  },
  "& .MuiInputBase-input": {
    maxWidth: 65,
    borderRadius: 4,
    position: "relative",
    backgroundColor: theme.palette.background.paper,
    border: "1px solid #ced4da",
    fontSize: 12,
    padding: "10px 26px 10px 12px",
    transition: theme.transitions.create(["border-color", "box-shadow"]),
    // Use the system font instead of the default Roboto font.
    fontFamily: [
      "-apple-system",
      "BlinkMacSystemFont",
      "Segoe UI",
      "Roboto",
      "Helvetica Neue",
      "Arial",
      "sans-serif",
      "Apple Color Emoji",
      "Segoe UI Emoji",
      "Segoe UI Symbol"
    ].join(","),
    "&:focus": {
      borderRadius: 4,
      borderColor: "#80bdff",
      boxShadow: "0 0 0 0.2rem rgba(0,123,255,.25)"
    }
  }
}));

const drawerWidth = 200;

function RatingIcon(props) {
  return (
    <SvgIcon {...props} viewBox="0 0 512 512">
      <path d="m256 8c-136.957 0-248 111.083-248 248 0 136.997 111.043 248 248 248s248-111.003 248-248c0-136.917-111.043-248-248-248zm0 110c23.196 0 42 18.804 42 42s-18.804 42-42 42-42-18.804-42-42 18.804-42 42-42zm56 254c0 6.627-5.373 12-12 12h-88c-6.627 0-12-5.373-12-12v-24c0-6.627 5.373-12 12-12h12v-64h-12c-6.627 0-12-5.373-12-12v-24c0-6.627 5.373-12 12-12h64c6.627 0 12 5.373 12 12v100h12c6.627 0 12 5.373 12 12z" />
    </SvgIcon>
  );
}
function Feedback(props) {
  return (
    <SvgIcon {...props} viewBox="0 0 33 32">
      <path d="m.064 24.032v-18.432q0-.032.096-.608l10.848 9.28-10.816 10.4q-.128-.448-.128-.64zm1.44-20.32q.224-.096.544-.096h29.216q.288 0 .576.096l-10.88 9.312-1.44 1.152-2.848 2.336-2.848-2.336-1.44-1.152zm.032 22.208 10.912-10.464 4.224 3.424 4.224-3.424 10.912 10.464q-.256.096-.544.096h-29.216q-.256 0-.512-.096zm20.8-11.648 10.816-9.28q.096.288.096.608v18.432q0 .288-.096.64z" />
    </SvgIcon>
  );
}
const style = {
  position: "absolute",
  top: "7%",
  transform: "translate(-0%, -0%)",
  maxWidth: "600px",
  bgcolor: "background.paper",
  borderRadius: "6px",
  boxShadow: "0 5px 15px rgba(0,0,0,.5)",
  p: 2,
  fontSize: "0.75rem",
  zIndex: "463287465",
  margin: "auto",
  left: "10%",
  right: "10%"
};
const trialSummary = {
  position: "absolute",
  top: "7%",
  transform: "translate(-0%, -0%)",
  maxWidth: "800px",
  bgcolor: "background.paper",
  borderRadius: "6px",
  boxShadow: "0 5px 15px rgba(0,0,0,.5)",
  p: 2,
  fontSize: "0.75rem",
  zIndex: "463287465",
  margin: "auto",
  left: "10%",
  right: "10%"
};

const FeedBackpopupstyle = {
  position: "relative",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: "100%",
  maxWidth: "600px",
  bgcolor: "background.paper",
  border: "1px solid rgba(0,0,0,.2)",
  borderRadius: "6px",
  boxShadow: "0 5px 15px rgba(0,0,0,.5)",
  p: 2,
  fontSize: "0.75rem",
  zIndex: "463287465"
};

class ViewerComponent extends Component {
  constructor(props) {
    super(props);
    this.mapRef = React.createRef();
    this.textareaRef = React.createRef();
    this.state = {
      gotCollectionData: false,
      dates: null,
      trials: null,
      colorMap: null,
      open: false,
      ratingOpen: false,
      vectorsList: null,
      collectionName: null,
      drawerOpen: true,
      rightDrawer: true,
      measureToolText: false,
      map: null,
      selectedTrail: "All trials",
      selectedDate: "",
      vectorsListSearch: null,
      feedbackName: "",
      feedbackEmail: "",
      feedbackMessage: "",
      vectorLayersList: null,
      activeOverlays: [],
      activerVectorLayers: [],
      inActiverVectorLayers: [],
      staticInActiverVectorLayers: [],
      selectedVectors: [],
      selectedVectorswithLetters: [],
      selectedVectorswithoutPlots: [],
      selectedVectorsOnlyPlots: [],
      blocks: [],
      count: 0,
      allVectors: [],
      selectedMeasurements: new Set(),
      letters: [
        "A",
        "B",
        "C",
        "D",
        "E",
        "F",
        "G",
        "H",
        "I",
        "J",
        "K",
        "L",
        "M",
        "N",
        "O",
        "P",
        "Q",
        "R",
        "S",
        "T",
        "U",
        "V",
        "W",
        "X",
        "Y",
        "Z"
      ],
      eyeOpenClosed: false,
      selectedVector: "",
      selectedVectorForHeatMap: "",
      currentRaster: "Base",
      res: null,
      currentTrial: "All trials",
      loadGraph: false,
      profileClick: false,
      anchorEl: null,
      allMeasurments: [],
      hasTreatment: false,
      overlayVectors: null,
      geojson: null,
      selectedDateCollectionId: null,
      isHeatMapActive: false,
      trialTextWidth: 0,
      rightDrawerDynamicWidth: 0,
      isChecked: false,
      dateLoader: false,
      loader: true,
      sendFeedBack: false,
      filterValue: "",
      ratingDescFilter: "",
      showMap: false,
      showViewerText: true,
      selectedAssessment: null,
      resizableHeight: 200,
      isDragging: false,
      initialPos: 0,
      initialSize: 0,
      selectedBoundingBoxes: false,
      selectedTab: "one",
      chartsValue: "Bar",
      selectedVectorForEyeOpen: "",
      isLogin: false,
      selectedValue: "",
      showDropDown: false,
      plotValue: [],
      plotNums: [],
      showTrailSummary: false,
      trailSummaryModal: false,
      isImgAvailable: false,
      isMobile: window.innerWidth < 577,
      isTablet: window.innerWidth > 577 && window.innerWidth < 870,
      isMobileLandscape: window.innerHeight < 431,
      isTabletLandScape: window.innerHeight <= 830,
      tabMaxWidth: window.innerWidth <= 1024
    };
  }

  handlePopState = (e) => {
    if (e?.state && e?.state?.idx) {
      window.location.reload();
    }
  };

  handleUrlChange() {
    history.pushState({ idx: true }, " ");
  }

  componentWillUnmount() {
    window.removeEventListener("resize", this.handleResize);
    window.removeEventListener("popstate", this.handlePopState);
    window.removeEventListener("popstate", this.handleResponsive);
  }
  handleResize = () => {
    const viewportWidth = window.innerWidth;
    if (viewportWidth < 600) {
      this.setState({ drawerOpen: false, showViewerText: false });
    } else {
      this.setState({ drawerOpen: true, rightDrawer: true, showViewerText: true });
    }
  };
  handleResponsive = () => {
    this.setState({
      isMobile: window.innerWidth < 577,
      isTablet: window.innerWidth > 577 && window.innerWidth < 870,
      isMobileLandscape: window.innerHeight < 431,
      isTabletLandScape: window.innerHeight <= 830,
      tabMaxWidth: window.innerWidth <= 1024
    });
  };
  handleTrailSummaryOpen = () => {
    this.setState({ trailSummaryModal: true });
  };

  handleTrailSummaryClose = () => {
    this.setState({ trailSummaryModal: false });
  };

  feedBackModalClose() {
    this.setState({ sendFeedBack: false });
  }
  handleOpen = () => {
    this.setState({ open: true });
  };
  handleRatingOpen = () => {
    this.setState({ ratingOpen: true });
  };

  async loginSyt1() {
    const queryParams = new URLSearchParams(window.location.search);
    const trial = queryParams.get("trial") != null ? queryParams.get("trial") : "";
    loginSyt(
      sessionStorage.getItem("idToken"),
      (res) => {
        if (res === 200) {
          this.setState({ isLogin: true });
          if (this.collectionId != null && this.collectionId != "") {
            getCollectionResponse(
              this.collectionId,
              (res) => {
                this.res = res;

                this.setState({ loader: false });
                if (this.res.overlay_vectors !== undefined && this.res.overlay_vectors.length > 0) {
                  this.setState({ overlayVectors: this.res.overlay_vectors[0] });
                }

                getRatingDescriptions(
                  (res) => {
                    this.ratingDescriptions = res;
                    this.callOtherFunctions();
                  },
                  (err) => {
                    console.log("err", err);
                  }
                );
              },
              (err) => {
                this.setState({ loader: false });
                console.log("err", err);
              }
            );
          } else {
            viewCollectionDetailsAPI(
              trial,
              (res) => {
                this.res = res;
                this.setState({ loader: false });
                if (this.res.overlay_vectors !== undefined && this.res.overlay_vectors.length > 0) {
                  this.setState({ overlayVectors: this.res.overlay_vectors[0] });
                }

                getRatingDescriptions(
                  (res) => {
                    this.ratingDescriptions = res;
                    this.callOtherFunctions();
                  },
                  (err) => {
                    console.log("err", err);
                  }
                );
              },
              (err) => {
                this.setState({ loader: false });
                console.log("err", err);
              }
            );
          }
        }
      },
      (err) => {
        console.log("err", err);
      }
    );
    // );
  }

  generateColorMap() {
    const boundColors = [
      "#1D7CF2",
      "#E32E30",
      "#FF8C00",
      "#FFD700",
      "#0000EE",
      "#DE85B1",
      "#B0E0E6",
      "#0198E1",
      "#EEEE00",
      "#FF34B3",
      "#D0A9AA",
      "#00CED1",
      "#E47833",
      "#E6E8FA",
      "#551A8B",
      "#8F8FBC"
    ];

    let colorMap = {};
    this.extractTrialNames().forEach((trialName, index) => {
      let j = index % boundColors.length;
      colorMap[trialName] = boundColors[j];
    });
    this.setState({ colorMap: colorMap });
    this.colorMap = colorMap;
  }

  extractVectorLayerNames() {
    let finalSet = new Set();

    this.res.measurement_sets.forEach((measurements) => {
      measurements.keys.forEach((key) => {
        finalSet.add(key);
      });
    });

    let trialMeasurements = this.extractVectorLayerMeasurements();
    if (Object.keys(trialMeasurements).length > 0) {
      finalSet.add("Plot #");
    }

    let has_treatments = false;
    Object.keys(trialMeasurements).every((trialName) => {
      trialMeasurements[trialName].measurements.every((measurement) => {
        let properties = measurement.feature.properties;
        if (properties.treatment !== undefined) {
          has_treatments = true;
        }
        return !has_treatments;
      });
      return !has_treatments;
    });
    if (has_treatments) {
      this.setState({ hasTreatment: true });
      finalSet.add("Treatment #");
    }
    return Array.from(finalSet);
  }
  extractVectorLayerNameswithCategories() {
    let finalSet = new Set();
    let isAdded = false;
    let titleValue = "";
    if (this.res.measurement_sets.length > 0) {
      let selectedCategories = this.res.measurement_sets[0].categories;
      selectedCategories = this.res.measurement_sets?.reduce((acc, data) => {
        Object.keys(data.categories)?.forEach((category) => {
          if (!acc[category]) {
            acc[category] = [];
          }
          data.categories[category]?.forEach((value) => {
            if (!acc[category].includes(value)) {
              acc[category].push(value);
            }
          });
        });
        return acc;
      }, {});
      for (const category of Object.entries(selectedCategories)) {
        // eslint-disable-next-line no-unused-vars
        for (const [key, value] of Object.entries(category)) {
          if (!Array.isArray(value)) {
            isAdded = false;
            titleValue = value;
          } else {
            for (let i = 0; i < value.length; i++) {
              if (value[i].toString().toLowerCase() !== "treatment") {
                const filteredData = this.extractVectorLayerNames().filter((item) => {
                  return item.toString().toLowerCase().includes(value[i].toLowerCase());
                });
                if (filteredData.length > 0) {
                  if (!isAdded) {
                    if (titleValue !== "") {
                      finalSet.add("Title: " + titleValue);
                    }
                    titleValue = "";
                    isAdded = true;
                  }
                  finalSet.add(value[i]);
                }
              }
            }
          }
        }
      }
    }
    return Array.from(finalSet);
  }

  buildMeasurementSetsFromVectors(vectors) {
    let measurementsSets = {};

    if (vectors.length > 0) {
      vectors[0].features.forEach((vector) => {
        if (measurementsSets[vector.properties.trial_name] === undefined) {
          measurementsSets[vector.properties.trial_name] = {
            measurements: [{ feature: vector, id: vector.id }]
          };
        } else {
          measurementsSets[vector.properties.trial_name].measurements.push({
            feature: vector,
            id: vector.id
          });
        }
      });

      return measurementsSets;
    }
  }

  extractVectorLayerMeasurements() {
    let vectors = {};

    this.res.measurement_sets.forEach((measurementSet) => {
      vectors[measurementSet.name] = {
        // id: measurementSet.id,
        keys: measurementSet.keys,
        measurements: measurementSet.measurements
      };
    });

    return { ...this.buildMeasurementSetsFromVectors(this.res.vectors), ...vectors };
  }

  extractCollectionName() {
    this.setState({ collectionName: this.res.name });
  }

  extractTrialNames() {
    let trialNames = new Set([]);

    this.res.vectors.forEach((vector) => {
      vector.features.forEach((feature) => {
        trialNames.add(feature.properties.trial_name);
      });
    });
    let trailDyanamicWidth = 0;
    if (Array.from(trialNames).length > 0) {
      trailDyanamicWidth = Array.from(trialNames)
        .sort()
        .reduce((pre, cur) => {
          return pre?.length > cur?.length ? pre?.length : cur?.length;
        });
    }

    this.setState({
      trials: Array.from(trialNames).sort(),
      trialTextWidth: trailDyanamicWidth
    });
    return Array.from(trialNames).sort();
  }

  extractAllDates() {
    this.setState({ dates: this.res.collection_ids });
  }

  extractZooms() {
    return this.res.base_layer.zooms;
  }

  extractBounds() {
    return L.latLngBounds(
      L.latLng(this.res.nswe[1], this.res.nswe[2]),
      L.latLng(this.res.nswe[0], this.res.nswe[3])
    );
  }

  extractBaseLayer() {
    return this.res.base_layer;
  }

  extractDemLayer() {
    return this.res.dem_layer;
  }

  extractNdviLayer() {
    return this.res.ndvi_layer;
  }

  extractExtraLayer(name) {
    return this.res.extra_layers[name];
  }

  getZooms() {
    const zooms = this.extractZooms();
    return {
      min: Math.min.apply(null, zooms),
      max: Math.max.apply(null, zooms)
    };
  }

  generateRasterLayers() {
    const makeLayer = (rasterlayer) => {
      if (rasterlayer === undefined || rasterlayer === null) {
        return undefined;
      }

      let layer_template;
      let zIndex = 1;
      if (rasterlayer.meta_data.imgtype !== undefined) {
        this.setState({ isImgAvailable: true });
      } else {
        this.setState({ isImgAvailable: false });
      }
      if (rasterlayer.meta_data.imgtype !== undefined && rasterlayer.meta_data.imgtype === "png") {
        layer_template = "/tiles/{baseName}/{z}/{x}/{y}.png";
        zIndex = 2;
      } else {
        layer_template = "/tiles/{baseName}/{z}/{x}/{y}.jpeg";
      }

      const tl = L.tileLayer(layer_template, {
        baseName: rasterlayer.s3_id,
        minZoom: this.extractZooms().min,
        maxZoom: this.extractZooms().max + 3,
        minNativeZoom: this.extractZoomsFromLayer(rasterlayer).min,
        maxNativeZoom: this.extractZoomsFromLayer(rasterlayer).max,
        bounds: this.extractBounds(),
        noWrap: true,
        zIndex: zIndex
      });

      tl.on("tileerror", async () => { });

      return tl;
    };

    const baseLayer = makeLayer(this.extractBaseLayer());
    const demLayer = makeLayer(this.extractDemLayer());
    const ndviLayer = makeLayer(this.extractNdviLayer());

    this.rasterLayers = {
      Base: baseLayer
    };
    if (demLayer !== undefined) {
      this.rasterLayers.Elevation = demLayer;
    } else if (this.state.currentRaster === "Elevation") {
      setTimeout(() => {
        this.rasterChange("Base");
      }, 100);
    }
    if (ndviLayer !== undefined) {
      this.rasterLayers.NDVI = ndviLayer;
    } else if (this.state.currentRaster === "NDVI") {
      setTimeout(() => {
        this.rasterChange("Base");
      }, 100);
    }
    this.getRasterLayers(this.rasterLayers);
    const extraLayerNames = ["VARI", "MCARI", "thermal"];
    extraLayerNames.forEach((layerName) => {
      const lyr = makeLayer(this.extractExtraLayer(layerName));
      if (lyr) {
        let upLayerName = layerName.charAt(0).toUpperCase() + layerName.slice(1);
        this.rasterLayers[upLayerName] = lyr;
      }
    });
    const altRgbLayer = makeLayer(this.extractExtraLayer("alt-rgb"));
    if (altRgbLayer) {
      this.rasterLayers["Alternate RGB"] = altRgbLayer;
    }

    this.overlayLayers = {};
    const vegLayer = makeLayer(this.extractExtraLayer("veg"));
    if (vegLayer) {
      this.overlayLayers.Crop = vegLayer;
    }
    const phytoLayer = makeLayer(this.extractExtraLayer("phyto"));
    if (phytoLayer) {
      this.overlayLayers.Phyto = phytoLayer;
    }
    const noncropLayer = makeLayer(this.extractExtraLayer("noncrop"));
    if (noncropLayer) {
      this.overlayLayers.Weeds = noncropLayer;
    }
    const clsLayer = makeLayer(this.extractExtraLayer("cls"));
    if (clsLayer) {
      this.overlayLayers.Classification = clsLayer;
    }
    this.getOverlayLayers();
  }

  generateVectorLayers() {
    const trialMeasurements = this.extractVectorLayerMeasurements();
    const that = this;

    let vectorLayers = {};

    Object.keys(trialMeasurements).forEach((trialName) => {
      vectorLayers[trialName] = trialMeasurements[trialName].measurements.reduce(
        (finalObject, measurement) => {
          finalObject[measurement.id] = L.geoJson(measurement.feature, {
            color: this.colorMap[trialName],
            fill: true,
            fillOpacity: 0
          });

          finalObject[measurement.id].on("mouseover", function () {
            const tooltipHTMLelement = this.getTooltip()._container;
            if (
              !tooltipHTMLelement.classList.contains("should-show") &&
              that.selectedMeasurements.size !== 0 &&
              !tooltipHTMLelement.classList.contains("soloed")
            ) {
              this.getTooltip().update();
              tooltipHTMLelement.style.zIndex = "1000000";
              tooltipHTMLelement.style.visibility = "visible";
            } else if (!tooltipHTMLelement.classList.contains("soloed")) {
              tooltipHTMLelement.style.zIndex = "100";
            }
          });

          finalObject[measurement.id].on("mouseout", function () {
            const tooltipHTMLelement = this.getTooltip()._container;

            if (
              !tooltipHTMLelement.classList.contains("should-show") &&
              !tooltipHTMLelement.classList.contains("soloed")
            ) {
              tooltipHTMLelement.style.visibility = "hidden";
            } else if (!tooltipHTMLelement.classList.contains("soloed")) {
              tooltipHTMLelement.style.zIndex = "100";
            }
          });

          return finalObject;
        },
        {}
      );
    });

    this.vectorLayers = vectorLayers;
  }

  generateTooltips(content) {
    const trialMeasurements = this.extractVectorLayerMeasurements();

    let tooltips = {};

    function contentFromOldContent(c, m) {
      let finalSet = new Set();

      if (!(c && m)) {
        return finalSet;
      }

      const possibleMeasurements = Object.keys(m);

      c.forEach((measurement) => {
        if (possibleMeasurements.includes(measurement)) {
          finalSet.add(measurement);
        }
      });

      return finalSet;
    }

    function addPlotTreatNumber(meas, plotNum, treatNum) {
      if (meas) {
        meas["Plot #"] = plotNum;
        meas["Treatment #"] = treatNum;
        return meas;
      } else {
        return {
          "Plot #": plotNum,
          "Treatment #": treatNum
        };
      }
    }
    let CustomTooltip = L.Tooltip.extend({
      _setPosition: function (pos) {
        let map = this._map,
          container = this._container,
          tooltipPoint = map.layerPointToContainerPoint(pos),
          centerPoint = map.latLngToContainerPoint(map.getCenter()),
          direction = this.options.direction,
          tooltipWidth = null,
          tooltipHeight = null,
          offset = L.point(this.options.offset),
          anchor = this._getAnchor();
        if (direction === "fast") {
          tooltipWidth = 44;
          if (window.eyeOpenClosed || window.isHeatMapActive) {
            tooltipHeight = 32;
          } else {
            if (window.assessmentsCount == 1 || window.assessmentsCount == 0) {
              tooltipHeight = 32;
            } else {
              tooltipHeight = window.assessmentsCount * 22;
            }
          }
          direction = "right";
        } else {
          tooltipWidth = container.offsetWidth;
          tooltipHeight = container.offsetHeight;
        }

        if (direction === "top") {
          pos = pos.add(
            L.point(-tooltipWidth / 2 + offset.x, -tooltipHeight + offset.y + anchor.y, true)
          );
        } else if (direction === "bottom") {
          pos = pos.subtract(L.point(tooltipWidth / 2 - offset.x, -offset.y, true));
        } else if (direction === "center") {
          pos = pos.subtract(
            L.point(tooltipWidth / 2 + offset.x, tooltipHeight / 2 - anchor.y + offset.y, true)
          );
        } else if (
          direction === "right" ||
          (direction === "auto" && tooltipPoint.x < centerPoint.x)
        ) {
          direction = "right";
          pos = pos.add(
            L.point(offset.x + anchor.x, anchor.y - tooltipHeight / 2 + offset.y, true)
          );
        } else {
          direction = "left";
          pos = pos.subtract(
            L.point(
              tooltipWidth + anchor.x - offset.x,
              tooltipHeight / 2 - anchor.y - offset.y,
              true
            )
          );
        }

        L.DomUtil.removeClass(container, "leaflet-tooltip-right");
        L.DomUtil.removeClass(container, "leaflet-tooltip-left");
        L.DomUtil.removeClass(container, "leaflet-tooltip-top");
        L.DomUtil.removeClass(container, "leaflet-tooltip-bottom");
        L.DomUtil.addClass(container, "leaflet-tooltip-" + direction);
        L.DomUtil.setPosition(container, pos);
      }
    });
    Object.keys(trialMeasurements).forEach((trialName) => {
      tooltips[trialName] = trialMeasurements[trialName].measurements.reduce(
        (finalObject, measurement) => {
          let properties = measurement.feature.properties;
          finalObject[measurement.id] = {
            tooltip: new CustomTooltip({
              permanent: true,
              direction: "fast",
              className: "text",
              opacity: 1
            }),
            plotNumber: properties.num,
            trialName: properties.trial_name,
            treatment: properties.treatment,
            measurements: addPlotTreatNumber(
              measurement.meas,
              properties.num,
              properties.treatment
            ),
            content: contentFromOldContent(content, measurement.meas)
          };

          let tooltip = finalObject[measurement.id];
          finalObject[measurement.id].tooltip.setContent(this.tooltipToString(tooltip));
          this.vectorLayers[trialName][measurement.id].bindTooltip(
            finalObject[measurement.id].tooltip
          );

          return finalObject;
        },
        {}
      );
    });

    this.tooltips = tooltips;
  }

  updateToolTipMeasurements(tooltip, meas) {
    if (tooltip.measurements[meas.vector] !== undefined) {
      if (meas.active) {
        tooltip.content.add(meas.vector);
      } else {
        tooltip.content.delete(meas.vector);
      }
    }
  }

  tooltipToString(tooltip) {
    return () => {
      function notApplicableIfNotExist(correspondingData, measurementToGet) {
        let val = correspondingData[measurementToGet];
        if (typeof val != "string" && isNaN(val)) {
          return "<span style='color: #890000'>n/a</span>";
        }
        if (val === undefined) {
          return "n/aa";
        }
        return val;
      }
      let finalString = "<table>";
      let hasPlot = false;
      let hasTreat = false;
      for (const item of this.state.selectedMeasurements.values()) {
        if (item.vector === "Plot #") {
          hasPlot = true;
        }
        if (item.vector === "Treatment #") {
          hasTreat = true;
        }
      }

      if (hasPlot || hasTreat) {
        finalString +=
          "<tr>" + `<span style="color:${this.colorMap[tooltip.trialName]};">&#9632;</span>`;
        if (hasPlot) {
          finalString +=
            (this.state.isImgAvailable ? "<span class='tooltip-shadow'>" : "<span>") +
            tooltip.plotNumber +
            "</span>";
        }
        if (hasTreat) {
          if (tooltip.treatment === undefined) {
            finalString += " []";
          } else {
            finalString +=
              (this.state.isImgAvailable ? "<span class='tooltip-shadow'>" : "<span>") +
              ` [${tooltip.treatment}]` +
              "</span>";
          }
        }
        finalString += "</tr>";
      }

      this.state.selectedMeasurements.forEach((measurement) => {
        if (measurement.vector !== "Plot #" && measurement.vector !== "Treatment #") {
          finalString +=
            "<tr><td>" +
            (this.state.isImgAvailable ? "<span class='tooltip-shadow'>" : "<span>") +
            (this.letterMap[measurement.vector] +
              ":&nbsp</span></td><td>" +
              (this.state.isImgAvailable ? "<span class='tooltip-shadow'> <b>" : "<span><b>") +
              notApplicableIfNotExist(tooltip.measurements, measurement.vector) +
              "</b></span></td></tr>");
        }
      });
      return finalString + "</table>";
    };
  }

  extractZoomsFromLayer(layer) {
    return {
      min: Math.min.apply(null, layer.zooms),
      max: Math.max.apply(null, layer.zooms)
    };
  }

  drawRasterLayer(newLayer) {
    // remove all other layers and add new one
    Object.keys(this.rasterLayers).forEach((layerKey) => {
      if (newLayer === layerKey) {
        this.rasterLayers[layerKey].addTo(this.map);
      } else {
        this.rasterLayers[layerKey].removeFrom(this.map);
      }
    });
  }

  setOverlays(overlays) {
    // go through all overlays and either add or remove them
    Object.keys(this.overlayLayers).forEach((layerKey) => {
      if (overlays.includes(layerKey)) {
        this.overlayLayers[layerKey].addTo(this.map);
      } else {
        this.overlayLayers[layerKey].removeFrom(this.map);
      }
    });
  }

  setNewTrial(newTrial) {
    if (newTrial === "All trials") {
      Object.keys(this.vectorLayers).forEach((trialName) => {
        Object.keys(this.vectorLayers[trialName]).forEach((feature) => {
          if (!this.map.hasLayer(this.vectorLayers[trialName][feature])) {
            this.vectorLayers[trialName][feature].addTo(this.map);
          }
        });
      });
    } else if (newTrial == "No trials") {
      this.clearVectors();
    } else if (newTrial) {
      Object.keys(this.vectorLayers).forEach((trialName) => {
        if (trialName === newTrial) {
          Object.keys(this.vectorLayers[trialName]).forEach((feature) => {
            if (!this.map.hasLayer(this.vectorLayers[trialName][feature])) {
              this.vectorLayers[trialName][feature].addTo(this.map);
            }
          });
        } else {
          Object.keys(this.vectorLayers[trialName]).forEach((feature) => {
            this.vectorLayers[trialName][feature].removeFrom(this.map);
          });
        }
      });
    }
    this.updateTooltipViewOnZoom();
    this.updateTooltipViewOnPan();
  }

  clearVectors() {
    Object.keys(this.vectorLayers).forEach((trialName) => {
      Object.keys(this.vectorLayers[trialName]).forEach((feature) => {
        this.vectorLayers[trialName][feature].removeFrom(this.map);
      });
    });
  }

  ShowFullMap() {
    this.state.map.setBearing(0);
    this.state.map.fitBounds(this.extractBounds());
  }
  async fetchRasterLayers(newDate) {
    const id = this.state.dates[newDate];
    this.setState({ selectedDateCollectionId: id });
    const queryParams = new URLSearchParams(window.location.search);
    const tri = queryParams.size === 2 ? `&trial=${this.state.selectedTrail}` : "";
    let path = `/oldviewer?collection_id=${id}${tri}`;
    this.props.navigate(path, {
      search: "?collection_id=${id}"
    });

    return new Promise(() => {
      getRasterLayersAPICall(
        id,
        (res) => {
          this.mergeNewRastersWithOldResponse(res);
          this.fetchVectorLayers(newDate);
        },
        (err) => {
          console.log("err", err);
        }
      );
    });
  }

  async fetchVectorLayers(newDate) {
    const id = this.res.collection_ids[newDate];
    return new Promise(() => {
      getMeasurmentSets(
        id,
        (overlays) => {
          this.setState({ overlayVectors: null });

          if (overlays.overlay_vectors !== undefined && overlays.overlay_vectors.length > 0) {
            this.setState({ overlayVectors: overlays.overlay_vectors[0] }, () => {
              if (this.state.selectedBoundingBoxes && this.state.overlayVectors.features !== null) {
                let overlayVectorLayers = [];
                let geojson = null;
                for (let key in this.state.overlayVectors.features) {
                  overlayVectorLayers.push(this.state.overlayVectors.features[key]);
                }
                geojson = L.geoJSON(overlayVectorLayers, {
                  color: "#FF0000",
                  fill: true,
                  fillOpacity: 0
                }).addTo(this.map);
                this.setState({ geojson: geojson });
              }
            });
          }
          this.mergeNewVectorsWithOldResponse(overlays);
          this.setState({
            vectorsList: Object.entries(this.ratingDescriptions).filter((ratingDesc) => {
              return this.extractVectorLayerNames().find(
                (vectorName) => vectorName === ratingDesc[0]
              );
            }),
            vectorsListSearch: Object.entries(this.ratingDescriptions).filter((ratingDesc) => {
              return this.extractVectorLayerNames().find(
                (vectorName) => vectorName === ratingDesc[0]
              );
            })
          });

          //To reload the measurement_sets - start
          const inActiverVectorLayers = this.extractVectorLayerNameswithCategories().filter(
            (item) => {
              return (
                item.toLowerCase() != "Plot #".toLowerCase() ||
                item.toLowerCase() != "Treatment #".toLowerCase()
              );
            }
          );

          this.setState({ staticInActiverVectorLayers: inActiverVectorLayers });
          this.setState({ inActiverVectorLayers: inActiverVectorLayers }, () => {
            this.setState({ allVectors: [] }, () => {
              this.setState({ selectedVectorsOnlyPlots: [] }, () => {
                this.setState({ selectedVectorswithoutPlots: [] }, () => {
                  if (inActiverVectorLayers.length > 0) {
                    if (this.state.selectedVectors.length > 0) {
                      let selectedVectorsOnlyPlots = this.state.selectedVectors.filter((item) => {
                        return item === "Plot #" || item === "Treatment #";
                      });
                      if (selectedVectorsOnlyPlots.length > 0) {
                        selectedVectorsOnlyPlots = selectedVectorsOnlyPlots.map((item) =>
                          Object.assign({}, { vector: item }, { letter: "" })
                        );
                      }

                      this.setState({ selectedVectorsOnlyPlots: selectedVectorsOnlyPlots }, () => {
                        let selectedVectorswithoutPlots = this.state.selectedVectors.filter(
                          (item) => {
                            return item !== "Plot #" && item !== "Treatment #";
                          }
                        );
                        if (selectedVectorswithoutPlots.length > 0) {
                          const inActiverVectorLayersSet = new Set();
                          this.state.inActiverVectorLayers.forEach(
                            inActiverVectorLayersSet.add,
                            inActiverVectorLayersSet
                          );

                          let selectedVectorswithoutPlots1 = selectedVectorswithoutPlots.filter(
                            (x) => inActiverVectorLayersSet.has(x)
                          );

                          selectedVectorswithoutPlots1 = selectedVectorswithoutPlots1.map(
                            (item, i) =>
                              Object.assign({}, { vector: item }, { letter: this.state.letters[i] })
                          );

                          let inActiverVectorLayers = this.state.inActiverVectorLayers;
                          Object.values(selectedVectorswithoutPlots1).forEach((item1) => {
                            inActiverVectorLayers = inActiverVectorLayers.filter((item) => {
                              return item.toLowerCase() != item1.vector.toLowerCase();
                            });
                          });
                          this.setState(
                            { selectedVectorswithoutPlots: selectedVectorswithoutPlots1 },
                            () => {
                              this.setState(
                                {
                                  inActiverVectorLayers: inActiverVectorLayers,
                                  selectedAssessment: this.state.selectedVectorswithoutPlots[0]
                                },
                                () => {
                                  this.setState(
                                    {
                                      allVectors: this.state.selectedVectorsOnlyPlots.concat(
                                        this.state.selectedVectorswithoutPlots
                                      )
                                    },
                                    () => {
                                      if (this.state.allVectors?.length === 0) {
                                        this.setState({ isChecked: false });
                                      } else {
                                        this.setState({ isChecked: true });
                                      }
                                      if (this.state.isHeatMapActive) {
                                        this.heatMapSoloMeasurement(
                                          this.state.selectedVectorForHeatMap
                                        );
                                      } else {
                                        this.unsoloMeasurement();
                                      }
                                      this.updateMeasurements({
                                        vectors: this.state.allVectors,
                                        trial: this.currentTrial,
                                        unsolo: true
                                      });
                                    }
                                  );
                                }
                              );
                            }
                          );
                        }
                      });
                    }
                  } else {
                    if (this.state.selectedVectors.length > 0) {
                      let selectedVectorsOnlyPlots = this.state.selectedVectors.filter((item) => {
                        return item === "Plot #" || item === "Treatment #";
                      });
                      if (selectedVectorsOnlyPlots.length > 0) {
                        selectedVectorsOnlyPlots = selectedVectorsOnlyPlots.map((item) =>
                          Object.assign({}, { vector: item }, { letter: "" })
                        );
                      }
                      this.setState({ selectedVectorsOnlyPlots: selectedVectorsOnlyPlots }, () => {
                        this.unsoloMeasurement();
                        this.updateMeasurements({
                          vectors: this.state.selectedVectorsOnlyPlots,
                          trial: this.currentTrial,
                          unsolo: true
                        });
                      });
                    }
                  }
                });
              });
            });
          });
          //To reload the measurement_sets - end

          this.updateView();
          this.updateViewForNewDate({
            selectedMeasurements: this.state.allVectors,
            raster: this.state.currentRaster,
            trial: this.state.selectedTrail,
            overlays: this.state.activeOverlays
          });
        },
        (err) => {
          console.log("err", err);
        }
      );
    });
  }

  mergeNewRastersWithOldResponse(res) {
    this.res = { ...this.res, ...res }; // merge the new response with the old one
  }

  mergeNewVectorsWithOldResponse(res) {
    this.res.measurement_sets = res.measurement_sets;
    this.setState({ allMeasurments: this.res.measurement_sets, dateLoader: false });
  }

  updateView() {
    this.clearLayers();
    this.generateRasterLayers();
    this.generateVectorLayers();
    this.generateTooltips(this.state.allVectors);
  }

  updateViewForNewDate(o) {
    this.selectedMeasurements = new Set(
      o.selectedMeasurements.map((measurement) => {
        return Object.assign({}, { vector: measurement.vector }, { letter: measurement.letter });
      })
    );

    this.letterMap = o.selectedMeasurements.reduce((finalObject, measurement) => {
      finalObject[measurement.vector] = measurement.letter;
      return finalObject;
    }, {});
    this.setState({ selectedMeasurements: this.selectedMeasurements }, () => {
      this.drawRasterLayer(o.raster);
      this.setOverlays(o.overlays);
      this.setNewTrial(o.trial);
    });
  }

  plotEyeOpenClosed(measurement, isHeatMapActive) {
    if (measurement === undefined) {
      if (!isHeatMapActive) {
        this.switchToolTip(1);
        if (this.state.eyeOpenClosed) {
          this.soloMeasurement(this.state.selectedVectorForEyeOpen?.vector);
        } else {
          this.unsoloMeasurement();
        }
        this.updateTooltipViewOnZoom();
        this.setState({ selectedVector: "" });
      } else {
        if (isHeatMapActive) {
          this.heatMapSoloMeasurement(this.state.selectedVectorForHeatMap);
        } else {
          this.soloMeasurement(measurement);
        }
        this.setState({ selectedVector: "" });
        this.switchToolTip(0);
      }
    } else {
      if (this.state.eyeOpenClosed) {
        this.soloMeasurement(measurement.text);
        this.switchToolTip(1);
      } else {
        if (isHeatMapActive) {
          this.heatMapSoloMeasurement(measurement.text);
          this.switchToolTip(0);
        } else {
          this.soloMeasurement(measurement.text);
        }
      }

      this.setState({ selectedVector: measurement?.text });
      if (this.state.selectedVectorForHeatMap.indexOf(measurement?.text) < 0 && isHeatMapActive) {
        this.removeHeatMap(this.state.selectedTrail);
      }
    }
  }

  eyeOpenClosed(measurement, isHeatMapActive) {
    if (measurement === undefined) {
      if (!isHeatMapActive) {
        this.switchToolTip(1);
        if (this.state.eyeOpenClosed) {
          this.soloMeasurement(this.state.selectedVectorForEyeOpen?.vector);
        } else {
          this.unsoloMeasurement();
        }
        this.updateTooltipViewOnZoom();
        this.setState({ selectedVector: "" });
      } else {
        if (isHeatMapActive) {
          this.heatMapSoloMeasurement(this.state.selectedVectorForHeatMap);
        } else {
          this.soloMeasurement(measurement);
        }
        this.setState({ selectedVector: "" });
        this.switchToolTip(0);
      }
    } else {
      if (this.state.eyeOpenClosed) {
        this.soloMeasurement(measurement.text.vector);
        this.switchToolTip(1);
      } else {
        if (isHeatMapActive) {
          this.heatMapSoloMeasurement(measurement.text.vector);
          this.switchToolTip(0);
        } else {
          this.soloMeasurement(measurement.text.vector);
        }
      }

      this.setState({ selectedVector: measurement?.text?.vector });
      if (
        this.state.selectedVectorForHeatMap.indexOf(measurement?.text?.vector) < 0 &&
        isHeatMapActive
      ) {
        this.removeHeatMap(this.state.selectedTrail);
      }
    }
  }
  updateMeasurements(measurements) {
    this.selectedMeasurements = new Set();
    this.letterMap = measurements.vectors.reduce((finalObject, measurement) => {
      finalObject[measurement.vector] = measurement.letter;
      return finalObject;
    }, {});

    Object.keys(this.tooltips).forEach((trialName) => {
      Object.keys(this.tooltips[trialName]).forEach((measurementId) => {
        const tooltip = this.tooltips[trialName][measurementId];
        measurements.vectors.forEach((measurement) => {
          this.selectedMeasurements.add(measurement);
          this.updateToolTipMeasurements(tooltip, measurement);
        });
      });
    });

    this.setState({ selectedMeasurements: this.selectedMeasurements }, () => {
      this.updateTooltipViewOnZoom();
    });
  }

  soloMeasurement(measurement) {
    Object.keys(this.tooltips).forEach((trialName) => {
      Object.keys(this.tooltips[trialName]).forEach((measurementId) => {
        if (this.map.hasLayer(this.vectorLayers[trialName][measurementId])) {
          const tooltip = this.tooltips[trialName][measurementId];
          tooltip.tooltip._container.classList.add("soloed");
          tooltip.tooltip.setContent(
            this.tooltipToSoloString(tooltip, measurement !== undefined ? measurement : "")
          );
        }
      });
    });
  }

  unsoloMeasurement() {
    Object.keys(this.tooltips).forEach((trialName) => {
      Object.keys(this.tooltips[trialName]).forEach((measurementId) => {
        if (this.map.hasLayer(this.vectorLayers[trialName][measurementId])) {
          const tooltip = this.tooltips[trialName][measurementId];
          tooltip.tooltip._container.classList.remove("soloed");
          tooltip.tooltip.setContent(this.tooltipToString(tooltip));
        }
      });
    });
  }

  tooltipToSoloString(tooltip, measurement) {
    const imageAvlbl = this.state.isImgAvailable;
    return () => {
      function notApplicableIfNotExist(correspondingData, measurementToGet) {
        let val = correspondingData[measurementToGet];
        if (typeof val !== "string" && isNaN(val)) {
          return "<span style='color: #890000'>n/a</span>";
        }
        if (imageAvlbl) {
          return `<span class='tooltip-shadow'>${val}</span>`;
        } else {
          return `<span>${val}</span>`;
        }
      }
      return notApplicableIfNotExist(tooltip.measurements, measurement);
    };
  }

  bbox_intersects(b1, b2) {
    let has_l = b2.l <= b1.r && b2.l >= b1.l;
    let has_r = b2.r <= b1.r && b2.r >= b1.l;
    let has_t = b2.t >= b1.t && b2.t <= b1.b;
    let has_b = b2.b >= b1.t && b2.b <= b1.b;

    return (has_l || has_r) && (has_t || has_b);
  }

  updateTooltipViewOnZoom() {
    const chosenTooltipBounds = [];

    Object.keys(this.vectorLayers).forEach((trialName) => {
      let firstFeatureKey = Object.keys(this.vectorLayers[trialName])[0];
      let exampleTooltip = this.tooltips[trialName][firstFeatureKey];
      let exOffsetHeight, exOffsetWidth;

      if (
        exampleTooltip != undefined &&
        this.map.hasLayer(this.vectorLayers[trialName][firstFeatureKey])
      ) {
        let tooltipElement = exampleTooltip.tooltip._container;
        if (!tooltipElement.classList.contains("soloed")) {
          exampleTooltip.tooltip.setContent(this.tooltipToString(exampleTooltip));
        }
        exOffsetWidth = tooltipElement.offsetWidth;
        exOffsetHeight = tooltipElement.offsetHeight;
      }

      Object.keys(this.vectorLayers[trialName]).forEach((plotFeature) => {
        const feature = this.vectorLayers[trialName][plotFeature];
        const tooltip = this.tooltips[trialName][plotFeature];
        const tooltipHTMLelement = feature.getTooltip()._container;

        // this culls out-of-view tooltips
        if (this.map.hasLayer(this.vectorLayers[trialName][plotFeature])) {
          // now we get center of feature as latlng, get the tooltip size in pixels (approx?),
          // project tooltip corners to latlng, make polygon, check intersection with each (?), only display those that are non intersecting
          const values = tooltipHTMLelement.style.transform.split(/\w+\(|\);?/);
          const transform = values[1].split(/,\s?/g).map((value) => {
            return parseInt(value.slice(0, -2));
          });

          if (this.selectedMeasurements.size === 0) {
            tooltipHTMLelement.style.visibility = "hidden";
            return;
          }

          const padding = 5;

          // tooltip position in pixels (top-left corner)
          const tooltipTopLeft = {
            x: transform[0] - padding,
            y: transform[1] - padding
          };

          const tooltipBottomRight = {
            x: tooltipTopLeft.x + exOffsetWidth + padding,
            y: tooltipTopLeft.y + exOffsetHeight + padding
          };

          const tooltipBBox = {
            t: tooltipTopLeft.y,
            l: tooltipTopLeft.x,
            b: tooltipBottomRight.y,
            r: tooltipBottomRight.x
          };

          if (tooltipHTMLelement.classList.contains("soloed")) {
            if (feature.getBounds().intersects(this.map.getBounds())) {
              tooltipHTMLelement.style.visibility = "visible";
            } else {
              tooltipHTMLelement.style.visibility = "hidden";
            }
          } else {
            let nonintersects = true;

            for (let i = 0; i < chosenTooltipBounds.length; i++) {
              if (this.bbox_intersects(tooltipBBox, chosenTooltipBounds[i])) {
                nonintersects = false;
                tooltipHTMLelement.style.visibility = "hidden";
                tooltipHTMLelement.classList.remove("should-show");
                tooltipHTMLelement.classList.add("dont-show");
                break;
              }
            }

            if (nonintersects) {
              if (feature.getBounds().intersects(this.map.getBounds())) {
                tooltip.tooltip.setContent(this.tooltipToString(tooltip));
                tooltipHTMLelement.style.visibility = "visible";
              } else {
                tooltipHTMLelement.style.visibility = "hidden";
              }

              tooltipHTMLelement.classList.remove("dont-show");
              tooltipHTMLelement.classList.add("should-show");
              tooltipHTMLelement.style.zIndex = "100";
              chosenTooltipBounds.push(tooltipBBox);
            }
          }
        }
      });
    });
  }

  updateTooltipViewOnPan() {
    Object.keys(this.vectorLayers).forEach((trialName) => {
      Object.keys(this.vectorLayers[trialName]).forEach((plotFeature) => {
        const feature = this.vectorLayers[trialName][plotFeature];
        const tooltipHTMLelement = feature.getTooltip()._container;
        const tooltip = this.tooltips[trialName][plotFeature];

        if (this.map.hasLayer(feature)) {
          if (
            feature.getBounds().intersects(this.map.getBounds()) &&
            this.selectedMeasurements.size !== 0 &&
            (tooltipHTMLelement.classList.contains("should-show") ||
              tooltipHTMLelement.classList.contains("soloed"))
          ) {
            if (!tooltipHTMLelement.classList.contains("soloed")) {
              tooltip.tooltip.setContent(this.tooltipToString(tooltip));
            }
            tooltipHTMLelement.style.visibility = "visible";
          } else {
            tooltipHTMLelement.style.visibility = "hidden";
          }
        }
      });
    });
  }

  bindCallbacks() {
    this.map.on("moveend", () => {
      if (this.map.getZoom() === this.oldZoom) {
        this.updateTooltipViewOnPan();
      }
      this.oldZoom = this.map.getZoom();
    });

    this.map.on("movestart", () => {
      this.oldZoom = this.map.getZoom();
    });

    this.map.on("zoomend", () => {
      this.updateTooltipViewOnZoom();
    });
  }

  clearLayers() {
    this.map.eachLayer((layer) => {
      this.map.removeLayer(layer);
    });
  }

  getRasterLayers() {
    this.setState({ rasterLayersList: Object.keys(this.rasterLayers) });
  }

  getOverlayLayers() {
    this.setState({ overlayLayersList: Object.keys(this.overlayLayers) });
  }

  rasterChange(filterValue) {
    this.setState({ currentRaster: filterValue });
    this.drawRasterLayer(filterValue);
    this.setNewTrial(this.currentTrial);
  }

  overlayChecklist(event) {
    const isChecked = event.target.checked;
    if (isChecked) {
      this.setState({ activeOverlays: this.state.activeOverlays.concat(event.target.value) }, () =>
        this.overlayChanged(this.state.activeOverlays)
      );
    } else {
      const index = this.state.activeOverlays.indexOf(event.target.value);
      this.state.activeOverlays.splice(index, 1);
      this.setState({ activeOverlays: this.state.activeOverlays }, () =>
        this.overlayChanged(this.state.activeOverlays)
      );
    }
  }

  overlayChanged(Overlays) {
    this.setOverlays(Overlays);
  }

  initMap() {
    let zooms = this.getZooms();
    let container = L.DomUtil.get("map");

    if (container != null) {
      container._leaflet_id = null;
    }
    this.map = L.map("map", {
      mapMaxZoom: zooms.max + 3,
      mapMinZoom: zooms.min,
      crs: L.CRS.EPSG3857,
      zoomControl: false,
      rotate: true,
      rotateControl: {
        closeOnZeroBearing: false
      },
      touchRotate: true
    }).setView([0, 0], zooms.max);
    this.map.setMinZoom(zooms.min);
    this.map.setMaxZoom(zooms.max + 3);
    this.map.fitBounds(this.extractBounds());
    this.map.setView(this.map.getCenter(), this.map.getZoom());
    this.map.touchRotate.enable();
    this.map.touchZoom.disable();
    this.map.compassBearing.disable();
    this.map.touchGestures.enable();
    this.map.rotateControl.setPosition("bottomright");
    this.map.setMaxBounds([
      [-90, -180],
      [90, 180]
    ]);
    this.rasterLayers["Base"].addTo(this.map);

    let that = this;
    let ZoomViewer = L.Control.extend({
      onAdd: function () {
        let container = L.DomUtil.create("div");
        let gauge = L.DomUtil.create("div");
        container.style.width = "5em";
        container.style.background = "rgba(255,255,255,0.5)";
        container.style.textAlign = "center";
        container.style.position = "fixed"; //new
        container.style.bottom = "5px"; //new
        container.style.right = "18px"; //new
        that.map.on("zoomstart zoom zoomend", function () {
          gauge.innerHTML = "zoom: " + that.map.getZoom();
        });
        gauge.innerHTML = "zoom: " + that.map.getZoom();
        container.appendChild(gauge);

        return container;
      }
    });

    new ZoomViewer({ position: "bottomright" }).addTo(this.map);

    L.control
      .zoom({
        position: "bottomright"
      })
      .addTo(this.map);
    this.setState({ map: this.map });
  }
  callOtherFunctions() {
    this.generateColorMap();
    this.generateRasterLayers();
    this.generateVectorLayers();
    this.generateTooltips();

    this.extractAllDates();

    this.getRasterLayers();
    this.getOverlayLayers();

    this.extractCollectionName();

    const initial_trial = "All trials";

    this.initMap();
    this.bindCallbacks();
    this.setNewTrial(this.trailIDforMap ? this.trailIDforMap : initial_trial);

    this.setState({
      vectorsList: Object.entries(this.ratingDescriptions).filter((ratingDesc) => {
        return this.extractVectorLayerNames().find((vectorName) => vectorName === ratingDesc[0]);
      }),
      vectorsListSearch: Object.entries(this.ratingDescriptions).filter((ratingDesc) => {
        return this.extractVectorLayerNames().find((vectorName) => vectorName === ratingDesc[0]);
      })
    });
    const activerVectorLayers = this.extractVectorLayerNames().filter((item) => {
      return (
        item.toLowerCase() == "Plot #".toLowerCase() ||
        item.toLowerCase() == "Treatment #".toLowerCase()
      );
    });

    this.setState({ activerVectorLayers: activerVectorLayers });

    const inActiverVectorLayers = this.extractVectorLayerNameswithCategories().filter((item) => {
      return (
        item.toLowerCase() != "Plot #".toLowerCase() ||
        item.toLowerCase() != "Treatment #".toLowerCase()
      );
    });
    const date = this.res?.name.split(" ");
    const params = new URLSearchParams(window.location.search);
    this.setState({
      selectedDate: date[date.length - 1],
      selectedTrail: params.get("trial") === null ? "All trials" : params.get("trial")
    });
    const newCollection = Object.entries(this.res?.collection_ids).filter((item) => {
      return (item[0]) == (date[date.length - 1])
    });
    if (params.get("collection_id") === null) {
      this.setState({ selectedDateCollectionId: newCollection.flat()[1] });
    }
    this.setState({ inActiverVectorLayers: inActiverVectorLayers });
    this.setState({ staticInActiverVectorLayers: inActiverVectorLayers });
    if (params.get("trial") !== null) {
      this.setState({ showTrailSummary: true });
    }

    this.setState({ allMeasurments: this.res.measurement_sets });
  }
  async componentDidMount() {
    this.map = new Map(this.mapRef.current);
    const params = new URLSearchParams(window.location.search);
    this.trailIDforMap = params.get("trial") !== null && params.get("trial");
    window.addEventListener("resize", this.handleResize);
    window.addEventListener("resize", this.handleResponsive);
    this.handleResize();
    this.handleResponsive();
    document.body.style.background = "#ddd";
    this.dates = [];
    this.trials = [];
    this.currentDate = null;
    this.currentTrial = null;
    this.trialColors = null;
    this.collectionName = null;
    this.rasterLayers = [];
    this.overlayLayers = [];
    this.vectorLayers = [];
    this.isMobile = null;
    this.ratingDescriptions = [];

    this._debugShapes = [];
    if (params.get("collection_id") === null) {
      this.collectionId = this.state.selectedDateCollectionId;
    } else {
      this.collectionId = params.get("collection_id") !== null && params.get("collection_id");
    }
    this.map = null;
    this.res = null;
    this.bounds = null;
    this.tooltips = null;
    this.colorMap = null;
    this.selectedMeasurements = new Set();
    this.ratingDescriptions = null;
    this.setState({ selectedDateCollectionId: this.collectionId });
    this.measureTool = L.control.measure({
      position: "topleft",
      primaryLengthUnit: "meters",
      secondaryLengthUnit: "feet",
      primaryAreaUnit: "sqmeters",
      secondaryAreaUnit: "acres",
      captureZIndex: 1111,
      popupOptions: {
        // standard leaflet popup options http://leafletjs.com/reference-1.3.0.html#popup-option
        className: "leaflet-measure-resultpopup",
        autoPanPadding: [10, 10]
      }
    });
    if (sessionStorage.getItem("idToken") !== null) {
      await this.loginSyt1();
    } else {
      window.location.reload();
      this.loginSyt1();
    }
    this.initUrl = window.location.href;
    window.addEventListener("popstate", this.handlePopState);
    if (window.location.href !== this.initUrl) {
      this.handleUrlChange();
    }
  }

  dateClicked(newDate) {
    this.fetchRasterLayers(newDate);
    this.setState({ selectedDate: newDate });
    this.setState({ filterValue: "" });
    this.setState({
      isHeatMapActive: false,
      dateLoader: true,
      eyeOpenClosed: false,
      selectedVectorForEyeOpen: ""
    });
    window.eyeOpenClosed = false;
    window.isHeatMapActive = false;
  }

  trialClicked(newTrial) {
    this.setNewTrial(newTrial);
    this.setState({ selectedTrail: newTrial });
    const queryParams = new URLSearchParams(window.location.search);
    const tri = queryParams.size === 2 ? `&trial=${newTrial}` : "";
    let path = `/oldviewer?collection_id=${this.state.selectedDateCollectionId}${tri}`;
    this.props.navigate(path, {
      search: "?collection_id=${id}"
    });

    if (this.state.isHeatMapActive) {
      // eslint-disable-next-line quotes
      let data = '{ "text" : { "vector": "' + this.state.selectedVectorForHeatMap + '" }}';
      this.eyeOpenClosed(JSON.parse(data), this.state.isHeatMapActive);
      this.applyHeatMap(this.state.selectedVectorForHeatMap, newTrial);
      this.switchToolTip(0);
      this.setState({ eyeOpenClosed: false, selectedVectorForEyeOpen: "" });
      window.eyeOpenClosed = false;
    } else {
      this.removeHeatMap(newTrial);
      this.switchToolTip(1);
    }
    this.setState({ selectedValue: "All subplots" });

    let plotList = new Set();
    this.res.measurement_sets.forEach((item) => {
      if (item?.name === newTrial) {
        item?.measurements.forEach((obj) => {
          const plotNumber = obj?.feature?.properties?.num;

          if (plotNumber.toString().length >= 5) {
            this.setState({ showDropDown: true });
            const plotLastTwoDigits = plotNumber.toString().slice(-2);

            if (
              plotLastTwoDigits[1] >= "1" &&
              plotLastTwoDigits[1] <= "9" &&
              plotLastTwoDigits[0] === "0"
            ) {
              plotList.add("All subplots");
              plotList.add(plotLastTwoDigits[1].toString());
            }
          } else {
            this.setState({ showDropDown: false });
          }
        });
      }
      this.setState({ plotNums: Array.from(plotList) });
    });
    if (newTrial !== "All trials" && newTrial !== "No trials") {
      this.setState({ showTrailSummary: true });
    } else {
      this.setState({ showTrailSummary: false });
    }
    this.dateClicked(this.state.selectedDate);
    window.eyeOpenClosed = false;
    window.isHeatMapActive = false;
  }

  toggleDrawer() {
    if (this.map) {
      this.map.invalidateSize();
    }
    this.setState({ drawerOpen: true });
  }

  closeDrawer() {
    if (this.map) {
      this.map.invalidateSize();
    }
    this.setState({ drawerOpen: false });
  }
  toggleRightDrawer() {
    this.setState({ rightDrawer: true });
  }

  toggleGraph() {
    this.setState({ loadGraph: false });
  }

  closeRightDrawer() {
    this.setState({ rightDrawer: false });
  }
  closeRightGraph() {
    this.setState({ loadGraph: true });
  }
  measureClick = () => {
    if (!this.state.measureToolText) {
      this.measureTool.addTo(this.map);
    } else {
      this.measureTool.remove();
    }
    this.setState({ measureToolText: !this.state.measureToolText });
  };

  feedbackClose() {
    this.setState({ open: false });
  }
  ratingModalClose() {
    this.setState({ ratingOpen: false });
  }

  ratingDescriptionsFilter(filterValue) {
    this.setState({ ratingDescFilter: filterValue });
    const vectorsData = this.state.vectorsListSearch;
    const filteredData = vectorsData.filter((item) => {
      return item[0].toString().toLowerCase().includes(filterValue.toString().toLowerCase());
    });
    this.setState({
      vectorsList: filteredData
    });
  }

  handleOnChange = (e) => {
    this.setState({ selectedValue: e.target.value });
  };

  closeFeedbackModal() {
    this.setState({ open: false });
  }

  sendFeedbackEmail() {
    this.setState({ open: false });
    this.setState({ sendFeedBack: true });
  }

  plotVectorChanged(event) {
    const isChecked = event.target.checked;
    this.setState({ isChecked: true });
    if (isChecked) {
      const assessmentsCount = parseInt(window.assessmentsCount);
      window.assessmentsCount = assessmentsCount + 1;

      this.setState(
        { selectedVectors: this.state.selectedVectors.concat(event.target.value) },
        () => {
          //Only Plot # and Treatment #
          let selectedVectorsOnlyPlots = this.state.selectedVectors.filter((item) => {
            return item === "Plot #" || item === "Treatment #";
          });
          if (selectedVectorsOnlyPlots.length > 0) {
            selectedVectorsOnlyPlots = selectedVectorsOnlyPlots.map((item) =>
              Object.assign({}, { vector: item }, { letter: "" })
            );
          }
          this.setState({ selectedVectorsOnlyPlots: selectedVectorsOnlyPlots }, () => {
            //Without Plot # and Treatment #

            const inActiverVectorLayers = this.state.inActiverVectorLayers.filter((item) => {
              return item.toLowerCase() != event.target.value.toLowerCase();
            });
            this.setState(
              {
                inActiverVectorLayers: inActiverVectorLayers,
                selectedAssessment: this.state.selectedVectorswithoutPlots[0]
              },
              () => {
                this.setState(
                  {
                    allVectors: this.state.selectedVectorsOnlyPlots.concat(
                      this.state.selectedVectorswithoutPlots
                    )
                  },
                  () => {
                    this.updateMeasurements({
                      vectors: this.state.allVectors,
                      trial: this.currentTrial,
                      unsolo: true
                    });
                    if (this.state.eyeOpenClosed) {
                      if (this.state.selectedVectorForEyeOpen?.vector === undefined) {
                        this.soloMeasurement(this.state.selectedVectorForEyeOpen);
                      } else {
                        this.soloMeasurement(this.state.selectedVectorForEyeOpen?.vector);
                      }
                    } else {
                      if (this.state.isHeatMapActive) {
                        this.heatMapSoloMeasurement(this.state.selectedVectorForHeatMap);
                      } else {
                        this.unsoloMeasurement();
                      }
                    }
                  }
                );
              }
            );
          });
        }
      );
    } else {
      const index = this.state.selectedVectors.indexOf(event.target.value);
      this.state.selectedVectors.splice(index, 1);
      if (this.state.selectedVectorForEyeOpen === event.target.value) {
        this.setState({ eyeOpenClosed: false, selectedVectorForEyeOpen: null });
        window.eyeOpenClosed = false;
      }
      const assessmentsCount = parseInt(window.assessmentsCount);
      window.assessmentsCount = assessmentsCount - 1;

      let selectedVectorsOnlyPlots = this.state.selectedVectors.filter((item) => {
        return item === "Plot #" || item === "Treatment #";
      });
      if (selectedVectorsOnlyPlots.length > 0) {
        selectedVectorsOnlyPlots = selectedVectorsOnlyPlots.map((item) =>
          Object.assign({}, { vector: item }, { letter: "" })
        );
      }
      this.setState({ selectedVectorsOnlyPlots: selectedVectorsOnlyPlots }, () => {
        this.setState(
          {
            allVectors: this.state.selectedVectorsOnlyPlots.concat(
              this.state.selectedVectorswithoutPlots
            )
          },
          () => {
            if (this.state.allVectors?.length === 0) {
              this.setState({ isChecked: false });
            } else {
              this.setState({ isChecked: true });
            }

            this.updateMeasurements({
              vectors: this.state.allVectors,
              trial: this.currentTrial,
              unsolo: true
            });

            if (this.state.isHeatMapActive) {
              this.heatMapSoloMeasurement(this.state.selectedVectorForHeatMap);
            } else {
              this.unsoloMeasurement();
            }
          }
        );
      });
    }
  }

  heatMapTooltipToSoloString(tooltip, measurement) {
    return () => {
      function notApplicableIfNotExist(correspondingData, measurementToGet) {
        let val = correspondingData[measurementToGet];
        if (typeof val != "string" && isNaN(val)) {
          return "<span style='color: #890000'>n/a</span>";
        }
        return val;
      }

      let finalString = "<table>";
      let hasPlot = false;
      let hasTreat = false;

      for (const item of this.state.allVectors.values()) {
        if (item.vector === "Plot #") {
          hasPlot = true;
        }
        if (item.vector === "Treatment #") {
          hasTreat = true;
        }
      }

      if (hasPlot || hasTreat) {
        finalString +=
          "<tr>" + `<span style="color:${this.colorMap[tooltip.trialName]};">&#9632;</span>`;
        if (hasPlot) {
          finalString +=
            (this.state.isImgAvailable ? "<span class='tooltip-shadow'>" : "<span>") +
            tooltip.plotNumber +
            "</span>";
        }
        if (hasTreat) {
          if (tooltip.treatment === undefined) {
            finalString += " []";
          } else {
            finalString +=
              (this.state.isImgAvailable ? "<span class='tooltip-shadow'>" : "<span>") +
              ` [${tooltip.treatment}]` +
              "</span>";
          }
        }
        finalString += "</tr>";
      }
      finalString +=
        "<tr><td>" +
        (this.state.isImgAvailable ? "<span class='tooltip-shadow'><b>" : "<span><b>") +
        notApplicableIfNotExist(tooltip.measurements, measurement) +
        "</b></span></td></tr>";

      return finalString + "</table>";
    };
  }

  heatMapSoloMeasurement(vector) {
    Object.keys(this.tooltips).forEach((trialName) => {
      Object.keys(this.tooltips[trialName]).forEach((measurementId) => {
        if (this.map.hasLayer(this.vectorLayers[trialName][measurementId])) {
          const tooltip = this.tooltips[trialName][measurementId];
          tooltip.tooltip._container.classList.add("soloed");
          tooltip.tooltip.setContent(
            this.heatMapTooltipToSoloString(tooltip, vector !== undefined ? vector : "")
          );
        }
      });
    });
  }
  vectorChangedRemove(event) {
    const isChecked = event.target.checked;
    if (!isChecked) {
      //Used for tooltip position
      const assessmentsCount = parseInt(window.assessmentsCount);
      window.assessmentsCount = assessmentsCount - 1;

      if (this.state.selectedVectorForEyeOpen?.vector === event.target.value) {
        this.setState({ eyeOpenClosed: false, selectedVectorForEyeOpen: null });
        window.eyeOpenClosed = false;
      }

      const index = this.state.selectedVectors.indexOf(event.target.value);
      this.state.selectedVectors.splice(index, 1);

      const selectedVectors = new Set();
      this.state.selectedVectors.forEach(selectedVectors.add, selectedVectors);
      let inActiveVectorLayers = [];
      inActiveVectorLayers = this.state.staticInActiverVectorLayers.filter(
        (x) => !selectedVectors.has(x)
      );
      this.setState({ selectedVectors: this.state.selectedVectors }, () => {
        this.setState(
          {
            inActiveVectorLayers: inActiveVectorLayers
          },
          () => {
            //Only Plot # and Treatment #
            let selectedVectorsOnlyPlots = this.state.selectedVectors.filter((item) => {
              return item === "Plot #" || item === "Treatment #";
            });
            if (selectedVectorsOnlyPlots.length > 0) {
              selectedVectorsOnlyPlots = selectedVectorsOnlyPlots.map((item) =>
                Object.assign({}, { vector: item }, { letter: "" })
              );
            }
            this.setState({ selectedVectorsOnlyPlots: selectedVectorsOnlyPlots }, () => {
              //Without Plot # and Treatment #
              let selectedVectorswithoutPlots = this.state.selectedVectors.filter((item) => {
                return item !== "Plot #" && item !== "Treatment #";
              });
              if (selectedVectorswithoutPlots.length > 0) {
                const inActiverVectorLayersSet = new Set();
                this.state.staticInActiverVectorLayers.forEach(
                  inActiverVectorLayersSet.add,
                  inActiverVectorLayersSet
                );

                selectedVectorswithoutPlots = selectedVectorswithoutPlots.filter((x) =>
                  inActiverVectorLayersSet.has(x)
                );

                selectedVectorswithoutPlots = selectedVectorswithoutPlots.map((item, i) =>
                  Object.assign({}, { vector: item }, { letter: this.state.letters[i] })
                );
              }

              this.setState({ selectedVectorswithoutPlots: selectedVectorswithoutPlots }, () => {
                this.vectorLayersFilter(this.state.filterValue);
                this.setState(
                  {
                    allVectors: this.state.selectedVectorsOnlyPlots.concat(
                      this.state.selectedVectorswithoutPlots
                    )
                  },
                  () => {
                    if (this.state.allVectors?.length === 0) {
                      this.setState({ isChecked: false });
                    } else {
                      this.setState({ isChecked: true });
                    }

                    this.updateMeasurements({
                      vectors: this.state.allVectors,
                      trial: this.currentTrial,
                      unsolo: true
                    });

                    if (this.state.eyeOpenClosed) {
                      if (this.state.selectedVectorForEyeOpen?.vector === undefined) {
                        this.soloMeasurement(this.state.selectedVectorForEyeOpen);
                      } else {
                        this.soloMeasurement(this.state.selectedVectorForEyeOpen?.vector);
                      }
                    } else {
                      if (this.state.isHeatMapActive) {
                        this.heatMapSoloMeasurement(this.state.selectedVectorForHeatMap);
                      } else {
                        this.unsoloMeasurement();
                      }
                    }
                  }
                );
              });
            });
          }
        );
      });
      if (event.target.value.indexOf(this.state.selectedVectorForHeatMap) === 0)
        this.switchHeatMap(undefined);
    }
  }

  vectorChanged(event) {
    const isChecked = event.target.checked;
    this.setState({ isChecked: true });
    if (isChecked) {
      //Used for tooltip position
      const assessmentsCount = parseInt(window.assessmentsCount);
      window.assessmentsCount = assessmentsCount + 1;

      this.setState(
        { selectedVectors: this.state.selectedVectors.concat(event.target.value) },
        () => {
          //Only Plot # and Treatment #
          let selectedVectorsOnlyPlots = this.state.selectedVectors.filter((item) => {
            return item === "Plot #" || item === "Treatment #";
          });
          if (selectedVectorsOnlyPlots.length > 0) {
            selectedVectorsOnlyPlots = selectedVectorsOnlyPlots.map((item) =>
              Object.assign({}, { vector: item }, { letter: "" })
            );
          }
          this.setState({ selectedVectorsOnlyPlots: selectedVectorsOnlyPlots }, () => {
            //Without Plot # and Treatment #
            let selectedVectorswithoutPlots = this.state.selectedVectors.filter((item) => {
              return item !== "Plot #" && item !== "Treatment #";
            });

            if (selectedVectorswithoutPlots.length > 0) {
              const inActiverVectorLayersSet = new Set();
              this.state.staticInActiverVectorLayers.forEach(
                inActiverVectorLayersSet.add,
                inActiverVectorLayersSet
              );

              selectedVectorswithoutPlots = selectedVectorswithoutPlots.filter((x) =>
                inActiverVectorLayersSet.has(x)
              );

              selectedVectorswithoutPlots = selectedVectorswithoutPlots.map((item, i) =>
                Object.assign({}, { vector: item }, { letter: this.state.letters[i] })
              );
            }

            this.setState({ selectedVectorswithoutPlots: selectedVectorswithoutPlots }, () => {
              const inActiverVectorLayers = this.state.inActiverVectorLayers.filter((item) => {
                return item.toLowerCase() != event.target.value.toLowerCase();
              });
              this.setState(
                {
                  inActiverVectorLayers: inActiverVectorLayers,
                  selectedAssessment: this.state.selectedVectorswithoutPlots[0]
                },
                () => {
                  this.setState(
                    {
                      allVectors: this.state.selectedVectorsOnlyPlots.concat(
                        this.state.selectedVectorswithoutPlots
                      )
                    },
                    () => {
                      if (this.state.eyeOpenClosed) {
                        if (this.state.selectedVectorForEyeOpen?.vector === undefined) {
                          this.soloMeasurement(this.state.selectedVectorForEyeOpen);
                        } else {
                          this.soloMeasurement(this.state.selectedVectorForEyeOpen?.vector);
                        }
                      } else {
                        if (this.state.isHeatMapActive) {
                          this.heatMapSoloMeasurement(this.state.selectedVectorForHeatMap);
                        } else {
                          this.unsoloMeasurement();
                        }
                      }
                      this.updateMeasurements({
                        vectors: this.state.allVectors,
                        trial: this.currentTrial,
                        unsolo: true
                      });
                    }
                  );
                }
              );
            });
          });
        }
      );
    } else {
      const index = this.state.allVectors.findIndex((item) => item.vector === event.target.value);
      this.state.allVectors.splice(index, 1);
      const index1 = this.state.selectedVectorsOnlyPlots.indexOf(event.target.value);
      this.state.selectedVectorsOnlyPlots.splice(index1, 1);
      const index2 = this.state.selectedVectors.indexOf(event.target.value);
      this.state.selectedVectors.splice(index2, 1);
      this.setState({ allVectors: this.state.allVectors }, () => {
        this.setState({ selectedVectorsOnlyPlots: this.state.selectedVectorsOnlyPlots }, () => {
          this.setState({ selectedVectors: this.state.selectedVectors }, () => {
            if (this.state.isHeatMapActive) {
              this.heatMapSoloMeasurement(this.state.selectedVectorForHeatMap);
            } else {
              this.unsoloMeasurement();
            }
            this.updateMeasurements({
              vectors: this.state.allVectors,
              trial: this.currentTrial,
              unsolo: false
            });
          });
        });
      });
    }
  }

  boundingBoxes(event) {
    const isChecked = event.target.checked;
    let overlayVectorLayers = [];
    let geojson = null;
    if (isChecked) {
      for (let key in this.state.overlayVectors.features) {
        overlayVectorLayers.push(this.state.overlayVectors.features[key]);
      }
      geojson = L.geoJSON(overlayVectorLayers, {
        color: "#FF0000",
        fill: true,
        fillOpacity: 0
      }).addTo(this.map);
      this.setState({ geojson: geojson });
      this.setState({ selectedBoundingBoxes: true });
    } else {
      this.map.removeLayer(this.state.geojson);
      this.setState({ selectedBoundingBoxes: false });
    }
  }

  switchHeatMap(measurement) {
    if (measurement === undefined) {
      this.removeHeatMap(this.state.selectedTrail);
      this.eyeOpenClosed(undefined, false);
    } else {
      if (this.state.selectedVectorForEyeOpen?.vector !== measurement?.text?.vector) {
        this.setState({ eyeOpenClosed: false }, () => {
          window.eyeOpenClosed = false;
          this.setState({ selectedVectorForEyeOpen: "" }, () => {
            this.eyeOpenClosed(measurement, true);
            this.applyHeatMap(measurement.text.vector, this.state.selectedTrail);
            this.setState({ isHeatMapActive: true });
            this.setState({ selectedVectorForHeatMap: measurement?.text?.vector });
          });
        });
      } else {
        this.eyeOpenClosed(measurement, true);
        this.applyHeatMap(measurement.text.vector, this.state.selectedTrail);
        this.setState({ isHeatMapActive: true });
        this.setState({ selectedVectorForHeatMap: measurement?.text?.vector });
      }
    }
  }

  applyHeatMap(measurementText, selectedTrail) {
    const colorShades = [
      "#24050F",
      "#480A1D",
      "#7E1132",
      "#B41847",
      "#E2285F",
      "#E74B7A",
      "#EE81A2",
      "#F3A5BC",
      "#F3A5BC",
      "#F8C9D7",
      "#FDEDF2"
    ];
    let measArray = [];
    measArray = this.findRanges(measurementText);

    const minValue = Math.min(...measArray);
    const maxValue = Math.max(...measArray);

    Object.keys(this.tooltips).forEach((trialName) => {
      Object.keys(this.tooltips[trialName]).forEach((measurementId) => {
        if (this.map.hasLayer(this.vectorLayers[trialName][measurementId])) {
          const tooltip = this.tooltips[trialName][measurementId];
          let itemValue = tooltip.measurements[measurementText];
          if (itemValue !== "nan" && itemValue !== "n/a") {
            const opacity = (itemValue - minValue) / (maxValue - minValue);
            let colorCode = "";
            if (opacity.toFixed(1) < 1) colorCode = colorShades[opacity.toFixed(1).split(".")[1]];
            else colorCode = colorShades[9];
            this.vectorLayers[trialName][measurementId].setStyle({
              fillColor: colorCode,
              fillOpacity: 1
            });
          } else {
            this.vectorLayers[trialName][measurementId].setStyle({
              fillColor: "#ffffff",
              fillOpacity: 0
            });
          }
          if (trialName !== selectedTrail && selectedTrail !== "All trials")
            this.vectorLayers[trialName][measurementId].removeFrom(this.map);
          else this.vectorLayers[trialName][measurementId].addTo(this.map);
        }
      });
    });
  }

  removeHeatMap(selectedTrail) {
    //remove the color
    Object.keys(this.vectorLayers).forEach((trialName) => {
      Object.keys(this.vectorLayers[trialName]).forEach((plotFeature) => {
        if (trialName !== selectedTrail && selectedTrail !== "All trials") {
          this.vectorLayers[trialName][plotFeature].removeFrom(this.map);
        } else {
          this.vectorLayers[trialName][plotFeature].setStyle({ fillColor: "none", fillOpacity: 1 });
          this.vectorLayers[trialName][plotFeature].addTo(this.map);
        }
      });
    });
    this.setState({ isHeatMapActive: false });
    this.setState({ selectedVectorForHeatMap: "" });
  }

  switchToolTip(opacity) {
    Object.keys(this.vectorLayers).forEach((trialName) => {
      Object.keys(this.vectorLayers[trialName]).forEach((plotFeature) => {
        const feature = this.vectorLayers[trialName][plotFeature];
        if (opacity === 0) {
          feature.on("mouseover", function () {
            toolTip.setOpacity(1);
          });
          feature.on("mouseout", function () {
            toolTip.setOpacity(0);
          });
        } else {
          feature.on("mouseover", function () {
            toolTip.setOpacity(1);
          });
          feature.on("mouseout", function () {
            toolTip.setOpacity(1);
          });
        }
        let toolTip = feature.getTooltip();
        toolTip.setOpacity(opacity);
      });
    });
  }

  findRanges(vectorVal) {
    const measArray = [];
    Object.keys(this.tooltips).forEach((trialName) => {
      Object.keys(this.tooltips[trialName]).forEach((measurementId) => {
        Object.keys(this.tooltips[trialName][measurementId].measurements).forEach((meas) => {
          if (
            meas === vectorVal &&
            this.tooltips[trialName][measurementId].measurements[meas] !== "nan" &&
            this.tooltips[trialName][measurementId].measurements[meas] !== "n/a"
          ) {
            measArray.push(this.tooltips[trialName][measurementId].measurements[meas]);
          }
        });
      });
    });
    return measArray;
  }

  vectorLayersFilter(filterValue) {
    this.setState({ filterValue: filterValue });
    let vectorsData = this.state.staticInActiverVectorLayers;
    const selectedVectors = new Set();
    for (let j = 0; j < this.state.selectedVectorswithoutPlots.length; j++) {
      selectedVectors.add(this.state.selectedVectorswithoutPlots[j].vector);
    }
    vectorsData = vectorsData.filter((x) => !selectedVectors.has(x));
    const filteredData = vectorsData.filter((item) => {
      return item.toString().toLowerCase().includes(filterValue.toString().toLowerCase());
    });

    this.setState({
      inActiverVectorLayers: filteredData
    });
  }

  clearAll() {
    window.eyeOpenClosed = false;
    window.isHeatMapActive = false;
    window.assessmentsCount = 0;
    const inActiverVectorLayers = this.extractVectorLayerNameswithCategories().filter((item) => {
      return (
        item.toLowerCase() != "Plot #".toLowerCase() ||
        item.toLowerCase() != "Treatment #".toLowerCase()
      );
    });
    this.setState({ selectedVectorForEyeOpen: "", eyeOpenClosed: false });
    this.setState({ inActiverVectorLayers: inActiverVectorLayers }, () => {
      this.setState({ selectedVectors: [] }, () => {
        this.setState({ selectedVectorsOnlyPlots: [] }, () => {
          this.setState({ selectedVectorswithoutPlots: [] }, () => {
            this.setState(
              {
                allVectors: this.state.selectedVectorsOnlyPlots.concat(
                  this.state.selectedVectorswithoutPlots
                )
              },
              () => {
                this.unsoloMeasurement();
                this.updateMeasurements({
                  vectors: this.state.allVectors,
                  trial: this.currentTrial,
                  unsolo: true
                });
              }
            );
          });
        });
      });
    });
    this.switchHeatMap(undefined);
    this.setState({ activerVectorLayers: null }, () => {
      const activerVectorLayers = this.extractVectorLayerNames().filter((item) => {
        return (
          item.toLowerCase() == "Plot #".toLowerCase() ||
          item.toLowerCase() == "Treatment #".toLowerCase()
        );
      });
      this.setState({ activerVectorLayers: activerVectorLayers });
      this.setState({ isChecked: false });
    });
  }

  handleMouseDown = (e) => {
    this.setState({
      isDragging: true,
      initialPos: e.clientY,
      initialSize: this.state.resizableHeight
    });
    document.addEventListener("mousemove", this.handleMouseMove);
    document.addEventListener("mouseup", this.handleMouseUp);
  };

  handleMouseMove = (e) => {
    if (!this.state.isDragging) return;
    if (parseInt(this.state.initialSize) + parseInt(e.clientY - this.state.initialPos) >= 200) {
      this.setState({
        resizableHeight:
          parseInt(this.state.initialSize) + parseInt(e.clientY - this.state.initialPos)
      });
    }
  };
  handleMouseUp = () => {
    this.setState({ isDragging: false });
    document.removeEventListener("mousemove", this.handleMouseMove);
    document.removeEventListener("mouseup", this.handleMouseUp);
  };

  touchStart = (e) => {
    this.setState({
      isDragging: true,
      initialPos: e.changedTouches[e.changedTouches.length - 1].pageY,
      initialSize: this.state.resizableHeight
    });
  };

  touchMove = (e) => {
    if (!this.state.isDragging) return;
    if (
      parseInt(this.state.initialSize) +
      parseInt(e.changedTouches[e.changedTouches.length - 1].pageY - this.state.initialPos) >=
      200
    ) {
      this.setState({
        resizableHeight:
          parseInt(this.state.initialSize) +
          parseInt(e.changedTouches[e.changedTouches.length - 1].pageY - this.state.initialPos)
      });
    }
  };

  touchEnd = () => {
    this.setState({ isDragging: false });
  };
  render() {
    const getDates = this.state.dates && Object.entries(this.state.dates);
    let droneFlightDates = [];
    getDates?.map((text) => {
      droneFlightDates.push({ x: text[0], y: "Drone Flight" });
    });
    const resizableStyle = {
      maxHeight: this.state.resizableHeight,
      display: "flex",
      overflowX: "hidden",
      overflowY: "scroll"
    };
    const name = sessionStorage.getItem("userName");
    const pro = name?.split(".");
    const parts = this.state.collectionName?.split(" ");
    let collectionNameSplit = parts?.slice(0, parts.length - 1).join(" ");
    const newCollectionName = `${collectionNameSplit} ${this.state.selectedDate}`;
    const newCollectionNameMobile = newCollectionName?.length > 35 ? "..." : "";
    document.title = this.state.collectionName
      ? `Viewer : ${collectionNameSplit} ${this.state.selectedDate}`
      : "Viewer | Loading...";

    const measureText = this.state.measureToolText ? "Exit Measure Tool" : "Measure Tool";
    const newViewerTrial =
      this.state.selectedTrail === "All trials" ? null : this.state.selectedTrail;
    return this.state.loader ? (
      <Stack
        alignItems="center"
        sx={{ display: "flex", justifyContent: "center", height: "100vh" }}>
        <CircularProgress
          size={150}
          thickness={4}
          sx={{
            color: "green",
            "--CircularProgress-size": "80px",
            top: "50%",
            left: "50%"
          }}
        />
      </Stack>
    ) : (
      <Box
        sx={{
          display: "flex",
          fontSize: "0.75rem",
          backgroundColor: "#ddd",
          paddingRight: "0px",
          paddingLeft: "0px",
          overflowY: "hidden",
          marginRight: "-25px"
        }}>
        <CssBaseline />

        <AppBar
          position="fixed"
          sx={{
            height: "51px !important",
            backgroundColor: "#000",
            display: "flex",
            zIndex: (theme) => theme.zIndex.drawer + 2,
            alignItems: "center"
          }}>
          <div className="header">
            <a className="logo" href="/app" id="viewer-link">
              <div className="d-inline-block align-top">
                {window.innerWidth < 600 ? (
                  <img
                    src={mobileLogo}
                    href="/"
                    alt="syngenta"
                    style={{
                      cursor: "pointer"
                    }}
                  />
                ) : (
                  <img
                    src={syngentaLogo}
                    href="/app"
                    alt="syngenta"
                    style={{
                      width: 100,
                      cursor: "pointer"
                    }}
                  />
                )}
              </div>
            </a>
            <span
              className="headerTitle"
              style={{
                position: !this.state.showViewerText ? "fixed" : null,
                marginTop: !this.state.showViewerText ? "8px" : "14px",
                marginLeft: !this.state.showViewerText ? "8px" : "15px"
              }}>
              {!this.state.showViewerText
                ? newCollectionName?.slice(0, 35) + newCollectionNameMobile
                : newCollectionName}
            </span>

            <span style={{ position: "absolute", right: "60px" }}>
              {!(
                this.state.isMobile ||
                this.state.isTablet ||
                this.state.isMobileLandscape ||
                this.state.tabMaxWidth
              ) && (
                  <ToggleSwitch
                    trial={newViewerTrial}
                    collectionId={this.state.selectedDateCollectionId}
                  />
                )}
            </span>
            <div className="header-right">
              <Button
                onClick={() => {
                  this.setState({ profileClick: true });
                }}>
                <Avatar
                  sx={{ bgcolor: "#006D82", width: 30, height: 30, fontSize: 13 }}
                  aria-label="recipe">
                  {pro && pro.length > 1 ? pro[0].charAt(0) + pro[1].charAt(0) : pro[0].charAt(0)}
                </Avatar>
              </Button>
              <Menu
                open={this.state.profileClick}
                anchorEl={this.state.anchorEl}
                onClose={() => {
                  this.setState({ profileClick: false });
                  this.setState({ anchor: null });
                }}
                onClick={() => {
                  this.setState({ profileClick: false });
                }}
                PaperProps={{
                  elevation: 0,
                  sx: {
                    overflow: "visible",
                    filter: "drop-shadow(0px 2px 8px rgba(0,0,0,0.32))",
                    mt: 1.5,
                    marginTop: "35px"
                  }
                }}
                transformOrigin={{ horizontal: "right", vertical: "top" }}
                anchorOrigin={{ horizontal: "right", vertical: "top" }}>
                <MenuItem>
                  <Typography sx={{ fontSize: ".875rem" }}>{name}</Typography>
                </MenuItem>
                {(this.state.isMobile ||
                  this.state.isTablet ||
                  this.state.isMobileLandscape ||
                  this.state.tabMaxWidth) && (
                    <MenuItem sx={{ paddingLeft: "3px" }}>
                      <ToggleSwitch
                        trial={newViewerTrial}
                        collectionId={this.state.selectedDateCollectionId}
                      />
                    </MenuItem>
                  )}
                <MenuItem sx={{ paddingLeft: "6px" }}>
                  <Button
                    sx={{
                      color: "green",
                      fontSize: ".875rem",
                      textTransform: "capitalize"
                    }}
                    onClick={() => {
                      this.props.navigate("/home/logout");
                    }}>
                    Logout
                  </Button>
                </MenuItem>
              </Menu>
            </div>
          </div>
        </AppBar>

        <div style={{ width: "100%", position: "fixed" }}>
          {this.state.dateLoader && (
            <Backdrop
              style={{ color: "#fff", zIndex: "1111111", width: "100%", opacity: "0.5" }}
              open={true}>
              <CircularProgress
                color="inherit"
                size={150}
                thickness={4}
                sx={{
                  "--CircularProgress-size": "80px",
                  top: "50%",
                  left: "50%"
                }}
              />
            </Backdrop>
          )}
          <Box
            component="nav"
            sx={{
              zIndex: "999",
              top: "100px",
              position: "absolute",
              display: "flex",
              width: { xs: drawerWidth },
              flexShrink: { sm: 0 }
            }}>
            {!this.state.drawerOpen ? (
              <Button
                size="large"
                onClick={() => this.toggleDrawer()}
                sx={{
                  marginLeft: "10px",
                  border: "2px solid black",
                  backgroundColor: "white",
                  height: "40px",
                  "&:hover": {
                    backgroundColor: "white"
                  }
                }}>
                <ChevronRightIcon
                  sx={{
                    color: "black",
                    stroke: "black",
                    strokeWidth: "1px",
                    "&:hover": {
                      backgroundColor: "white"
                    }
                  }}
                />
              </Button>
            ) : (
              <Drawer
                variant="persistent"
                sx={{
                  display: { xs: "block" },
                  "& .MuiDrawer-paper": {
                    boxSizing: "border-box",
                    width: drawerWidth + this.state.trialTextWidth * 0.8,
                    overflowY: "hidden"
                  }
                }}
                anchor="left"
                open>
                <div
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    height: window.innerWidth > 600 && window.innerWidth < 1000 ? "93%" : "100%",
                    boxShadow: "0 0 10px rgba(0, 0, 0, 0.3)"
                  }}>
                  <Toolbar style={{ minHeight: "50px" }} />
                  <Divider />
                  <List style={{ flexGrow: 1, overflow: "auto" }}>
                    <ListItem disablePadding>
                      <ListItemButton sx={{ padding: "2px 8px" }}>
                        <ListItemText
                          primary={
                            <Typography
                              style={{
                                fontSize: "16px",
                                textAlign: "center",
                                paddingLeft: "10px"
                              }}>
                              Dates
                            </Typography>
                          }
                        />
                        <ListItemIcon
                          sx={{
                            minWidth: "30px",
                            justifyContent: "end",
                            color: "#333",
                            border: "1px solid black",
                            borderRadius: "3px"
                          }}
                          onClick={() => this.closeDrawer()}>
                          {
                            <ChevronLeftIcon
                              sx={{
                                display: "block",
                                border: "1px solid #ccc",
                                borderRadius: "3px",
                                width: "30px",
                                stroke: "black",
                                strokeWidth: "1px"
                              }}
                            />
                          }
                        </ListItemIcon>
                      </ListItemButton>
                    </ListItem>
                    <Divider />
                    {this.state.dates != null &&
                      Object.entries(this.state.dates)?.map((text, index) => (
                        <>
                          <ListItem key={text[0]} disablePadding>
                            <ListItemButton
                              onClick={() => {
                                this.dateClicked(text[0]);
                              }}
                              sx={{
                                padding: "1px 8px",
                                backgroundColor: this.state.selectedDate === text[0] && "black",
                                color: this.state.selectedDate === text[0] ? "white" : "black",
                                "&:hover": {
                                  backgroundColor: "#DDDDDD",
                                  color: "black"
                                }
                              }}>
                              <ListItemText
                                primary={
                                  <Typography variant="h6" style={{ fontSize: ".875rem" }}>
                                    {text[0]}
                                  </Typography>
                                }
                              />
                              <ListItemIcon
                                sx={{ minWidth: "30px", justifyContent: "end", color: "#333" }}>
                                {
                                  <ChevronRightIcon
                                    sx={{
                                      stroke:
                                        this.state.selectedDate === text[0] ? "white" : "black",
                                      strokeWidth: "1px",
                                      fill: this.state.selectedDate === text[0] ? "white" : "black",
                                      "&:hover": {
                                        strokeWidth: "1px",
                                        stroke: this.state.selectedDate === text[0] && "black"
                                      }
                                    }}
                                  />
                                }
                              </ListItemIcon>
                            </ListItemButton>
                          </ListItem>
                          {index === Object.entries(this.state.dates).length - 1 ? null : (
                            <Divider />
                          )}
                        </>
                      ))}
                    <Typography align="center" style={{ fontSize: "16px", padding: "4px 8px" }}>
                      Trial IDs
                    </Typography>
                    <Divider />
                    <ListItem disablePadding>
                      <ListItemButton
                        sx={{
                          padding: "2px 8px",
                          backgroundColor: this.state.selectedTrail === "All trials" && "black",
                          color: this.state.selectedTrail === "All trials" ? "white" : "black",
                          "&:hover": {
                            backgroundColor: "#DDDDDD",
                            color: "black"
                          }
                        }}
                        onClick={() => {
                          this.trialClicked("All trials");
                        }}>
                        <ListItemText
                          primary={
                            <Typography variant="h6" style={{ fontSize: ".875rem" }}>
                              All Trials
                            </Typography>
                          }
                        />
                        <ListItemIcon
                          sx={{
                            minWidth: "30px",
                            justifyContent: "end",
                            color: this.state.selectedTrail === "All trials" ? "white" : "black"
                          }}>
                          {
                            <ChevronRightIcon
                              sx={{
                                strokeWidth: "1px",
                                stroke:
                                  this.state.selectedTrail === "All trials" ? "white" : "black",
                                "&:hover": {
                                  strokeWidth: "1px",
                                  stroke:
                                    this.state.selectedTrail === "All trials" ? "white" : "black"
                                }
                              }}
                            />
                          }
                        </ListItemIcon>
                      </ListItemButton>
                    </ListItem>
                    <Divider />
                    <ListItem disablePadding>
                      <ListItemButton
                        sx={{
                          padding: "2px 8px",
                          backgroundColor: this.state.selectedTrail === "No trials" && "black",
                          color: this.state.selectedTrail === "No trials" ? "white" : "black",
                          "&:hover": {
                            backgroundColor: "#DDDDDD",
                            color: "black"
                          }
                        }}
                        onClick={() => {
                          this.trialClicked("No trials");
                        }}>
                        <ListItemText
                          primary={
                            <Typography variant="h6" style={{ fontSize: ".875rem" }}>
                              No Trials
                            </Typography>
                          }
                        />
                        <ListItemIcon
                          sx={{
                            minWidth: "30px",
                            justifyContent: "end",
                            color: this.state.selectedTrail === "No trials" ? "white" : "#333"
                          }}>
                          {
                            <ChevronRightIcon
                              sx={{
                                strokeWidth: "1px",
                                stroke:
                                  this.state.selectedTrail === "No trials" ? "white" : "black",
                                "&:hover": {
                                  strokeWidth: "1px",
                                  stroke: this.state.selectedTrail === "No trials" && "black"
                                }
                              }}
                            />
                          }
                        </ListItemIcon>
                      </ListItemButton>
                    </ListItem>
                    <Divider />
                    {this.state.trials != null &&
                      Object.values(this.state.trials)?.map((text, index) => (
                        <>
                          <ListItem key={text} disablePadding>
                            <ListItemButton
                              onClick={() => {
                                this.trialClicked(text);
                              }}
                              sx={{
                                padding: "1px 8px",
                                backgroundColor: this.state.selectedTrail === text && "black",
                                color: this.state.selectedTrail === text && "white",
                                "&:hover": {
                                  backgroundColor: "#DDDDDD",
                                  color: "black"
                                }
                              }}>
                              <div
                                className="color-code"
                                style={{ backgroundColor: this.state.colorMap[text] }}></div>
                              <ListItemText
                                primary={
                                  <Typography
                                    variant="h6"
                                    style={{
                                      fontSize: ".875rem"
                                    }}>
                                    {text}
                                  </Typography>
                                }
                              />
                              <ListItemIcon
                                sx={{
                                  minWidth: "30px",
                                  justifyContent: "end",
                                  color: this.state.selectedTrail === text ? "white" : "black"
                                }}>
                                {
                                  <ChevronRightIcon
                                    sx={{
                                      strokeWidth: "1px",
                                      stroke: this.state.selectedTrail === text ? "white" : "black",
                                      "&:hover": {
                                        strokeWidth: "1px",
                                        stroke: this.state.selectedTrail === text && "black"
                                      }
                                    }}
                                  />
                                }
                              </ListItemIcon>
                            </ListItemButton>
                          </ListItem>
                          {index === Object.entries(this.state.trials).length - 1 ? null : (
                            <Divider />
                          )}
                        </>
                      ))}
                  </List>
                  <Divider />
                  <Tooltip
                    title={
                      this.state.showTrailSummary === false
                        ? "Select a trial from above in order to view trial summary"
                        : ""
                    }>
                    <List
                      sx={{
                        position: "sticky",
                        padding: 0,
                        boxShadow: "0 -10px 10px -5px rgba(0, 0, 0, .17)"
                      }}>
                      <ListItem disablePadding>
                        <ListItemButton
                          disabled={this.state.showTrailSummary === false}
                          sx={{
                            padding: "4px 8px",
                            "&:hover": {
                              backgroundColor: "#DDDDDD",
                              color: "black"
                            }
                          }}
                          onClick={this.handleTrailSummaryOpen}>
                          <img
                            src={triaLogo}
                            alt="trialLogo"
                            style={{ width: "8%", height: "8%" }}
                          />
                          <ListItemText
                            primary={
                              <Typography variant="h6" style={{ fontSize: ".813rem" }}>
                                Trial Summary
                              </Typography>
                            }
                          />
                        </ListItemButton>
                      </ListItem>
                    </List>
                  </Tooltip>
                  <Divider />
                  <List sx={{ marginTop: "auto" }}>
                    <Typography
                      align="center"
                      style={{ fontSize: ".875rem", padding: "4px 20px 8px 0px" }}>
                      Tools
                    </Typography>
                    <Divider />
                    {/* {this.state.selectedTrail &&
                      this.state.selectedTrail !== "All trials" &&
                      this.state.selectedTrail !== "No trials" && (
                        <> */}
                    <ListItem disablePadding>
                      <ListItemButton
                        id="enter-measure-btn"
                        sx={{
                          padding: "4px 8px",
                          "&:hover": {
                            backgroundColor: "#DDDDDD",
                            color: "black"
                          }
                        }}
                        onClick={() => {
                          window.open(
                            `/app/ploteditor?collection_id=${this.state.selectedDateCollectionId}&trial=${newViewerTrial}`,
                            "_blank"
                          );
                        }}>
                        <EditIcon sx={{ fontSize: "18px", color: "#333", paddingRight: "5px" }} />
                        <ListItemText
                          primary={
                            <Typography variant="h6" style={{ fontSize: ".813rem" }}>
                              Draw Plots
                            </Typography>
                          }
                        />
                      </ListItemButton>
                    </ListItem>
                    <Divider />
                    {/* </>
                      )} */}
                    {window.innerWidth < 600 ? (
                      ""
                    ) : (
                      <ListItem disablePadding>
                        <ListItemButton
                          id="enter-measure-btn"
                          sx={{
                            padding: "4px 8px",
                            "&:hover": {
                              backgroundColor: "#DDDDDD",
                              color: "black"
                            }
                          }}
                          onClick={this.measureClick}>
                          {/*  */}
                          <EditIcon sx={{ fontSize: "18px", color: "#333", paddingRight: "5px" }} />
                          <ListItemText
                            primary={
                              <Typography variant="h6" style={{ fontSize: ".813rem" }}>
                                {measureText}
                              </Typography>
                            }
                          />
                        </ListItemButton>
                      </ListItem>
                    )}
                    <Divider />
                    <ListItem disablePadding>
                      <ListItemButton
                        sx={{
                          padding: "4px 8px",
                          "&:hover": {
                            backgroundColor: "#DDDDDD",
                            color: "black"
                          }
                        }}
                        onClick={() => this.ShowFullMap()}>
                        <ZoomOutMapIcon
                          sx={{ fontSize: "18px", color: "#333", paddingRight: "5px" }}
                        />

                        <ListItemText
                          primary={
                            <Typography variant="h6" style={{ fontSize: ".813rem" }}>
                              Show Full Map
                            </Typography>
                          }
                        />
                      </ListItemButton>
                    </ListItem>
                    <Divider />
                    <ListItem disablePadding>
                      <ListItemButton
                        sx={{
                          padding: "4px 8px",
                          "&:hover": {
                            backgroundColor: "#DDDDDD",
                            color: "black"
                          }
                        }}
                        onClick={this.handleOpen}>
                        <Feedback sx={{ fontSize: "18px", color: "#333", paddingRight: "5px" }} />

                        <ListItemText
                          primary={
                            <Typography variant="h6" style={{ fontSize: ".813rem" }}>
                              Send Feedback
                            </Typography>
                          }
                        />
                      </ListItemButton>
                    </ListItem>
                    <Divider />
                    <ListItem disablePadding>
                      <ListItemButton
                        sx={{
                          padding: "4px 8px",
                          "&:hover": {
                            backgroundColor: "#DDDDDD",
                            color: "black"
                          }
                        }}
                        onClick={this.handleRatingOpen}>
                        <RatingIcon sx={{ fontSize: "18px", color: "#333", paddingRight: "5px" }} />

                        <ListItemText
                          primary={
                            <Typography variant="h6" style={{ fontSize: ".813rem" }}>
                              Rating Descriptions
                            </Typography>
                          }
                        />
                      </ListItemButton>
                    </ListItem>
                  </List>
                </div>
              </Drawer>
            )}
          </Box>
          <Box
            component="main"
            sx={{ flexGrow: 1, width: { sm: "100%" }, height: "100vh", flexShrink: { sm: 0 } }}>
            <Toolbar />
            <Grid
              container
              ref={this.mapRef}
              id="map"
              className="leaflet-container leaflet-retina"
              sx={{ width: "91%", position: "none" }}></Grid>
            <Modal
              open={this.state.open} // onClose={this.handleClose}
              onClose={() => {
                this.feedbackClose();
              }}
              sx={{ overflowY: "scroll", height: "auto", top: 0 }}
              scroll="body"
              aria-labelledby="modal-modal-title"
              aria-describedby="modal-modal-description">
              <Box sx={style}>
                <Typography id="modal-modal-title" variant="h6" sx={{ fontSize: "18px" }}>
                  Send Feedback on the Digital Data Viewer
                </Typography>

                <Typography id="modal-modal-description" sx={{ mt: 1, fontSize: "14px" }}>
                  Have any questions or thoughts on how we improve the Digital Data Viewer, send us
                  a note here and we will take actions or follow-up with you to get more details.
                </Typography>

                <Typography id="modal-modal-description" sx={{ mt: 1, mb: 1, fontSize: "14px" }}>
                  To send us more detailed information (e.g. screenshots), email
                  alissa.kriss@syngenta.com
                </Typography>

                <Typography id="modal-modal-description" sx={{ mt: 1, mb: 3, fontSize: "14px" }}>
                  Drone pilots who need changes to plot boundaries, data outputs, or other
                  operational needs, please use the standard Ops form to connect with the DDC team.
                </Typography>

                <Divider fullWidth />
                {this.state.open ? (
                  <MyForm
                    sendFeedbackEmail={() => this.sendFeedbackEmail()}
                    isOpen={this.state.open}
                    currentDate={this.state.selectedDate}
                    currentTrial={this.state.selectedTrail}
                    collectionName={this.state.collectionName}
                    collectionId={this.state.selectedDateCollectionId}
                    closeFeedbackModal={() => this.closeFeedbackModal()}
                  />
                ) : (
                  ""
                )}
              </Box>
            </Modal>

            <Modal
              open={this.state.ratingOpen}
              onClose={() => {
                this.ratingModalClose();
              }}
              sx={{ overflowY: "scroll", height: "auto", top: 0 }}
              aria-labelledby="modal-modal-title"
              aria-describedby="modal-modal-description">
              <Box sx={style}>
                <Typography id="modal-modal-title" variant="h6" component="h2">
                  Rating Descriptions
                </Typography>
                <Typography id="modal-modal-description" sx={{ mt: 1, mb: 3 }}>
                  <TextField
                    multiline={true}
                    id="outlined-required"
                    label={
                      <Typography sx={{ fontSize: "12px", fontFamily: "Helvetica" }}>
                        Find ratings...
                      </Typography>
                    }
                    fullWidth
                    size="small"
                    sx={{
                      "& label.Mui-focused": {
                        color: "black",
                        borderRadius: "1px"
                      },
                      "& .MuiOutlinedInput-root": {
                        height: "32px",

                        "&.Mui-focused fieldset": {
                          borderColor: "#66afe9",
                          boxShadow:
                            "inset 0 1px 1px rgba(0,0,0,.075), 0 0 8px rgba(102,175,233,.6)"
                        }
                      }
                    }}
                    onChange={(e) => {
                      this.ratingDescriptionsFilter(e.target.value);
                    }}
                    value={this.state.ratingDescFilter}
                    InputProps={{
                      style: { fontSize: 12 },
                      endAdornment: this.state.ratingDescFilter !== "" && (
                        <IconButton
                          aria-label="toggle password visibility"
                          onClick={() => {
                            this.setState({ ratingDescFilter: "" }, () => {
                              this.ratingDescriptionsFilter("");
                            });
                          }}>
                          <CloseIcon style={{ fontSize: "12px" }} />
                        </IconButton>
                      )
                    }}
                  />
                </Typography>
                <Divider />
                <Box
                  sx={{
                    mb: 2,
                    display: "flex",
                    flexDirection: "column",
                    height: 380,
                    width: "auto",
                    overflow: "hidden",
                    overflowY: "scroll"
                    // justifyContent="flex-end" # DO NOT USE THIS WITH 'scroll'
                  }}>
                  {this.state.vectorsList &&
                    Object.values(this.state.vectorsList)?.map((txt) => (
                      <Typography id="modal-modal-description" key={txt[0]} sx={{ mt: 2 }}>
                        <div
                          style={{
                            overflow: "hidden",
                            fontSize: "14px",
                            lineHeight: "20px",
                            paddingRight: "15px"
                          }}>
                          <span style={{ fontWeight: 700, fontSize: "14px" }}>
                            {txt[0]?.split("v:")[0]}
                          </span>
                          {txt[0]?.split("v:").length > 1 && (
                            <span>
                              <i
                                style={{
                                  fontSize: "11.9px",
                                  color: "rgb(102, 102, 102)",
                                  fontWeight: 700,
                                  fontFamily: "sans-serif"
                                }}>
                                ({txt[0]?.split(" ").pop()})
                              </i>
                            </span>
                          )}
                          <strong>:</strong> {txt[1]}
                        </div>
                      </Typography>
                    ))}
                </Box>
                <Divider />
                <Typography sx={{ mt: 2 }} align="right">
                  <Button
                    variant="contained"
                    style={{ textTransform: "none" }}
                    onClick={() => {
                      this.setState({ ratingOpen: false });
                    }}>
                    Close
                  </Button>
                </Typography>
              </Box>
            </Modal>
            <Modal
              open={this.state.sendFeedBack}
              onClose={() => {
                this.feedBackModalClose();
              }}>
              <Box sx={FeedBackpopupstyle}>
                <Typography id="modal-modal-title" variant="h6" component="h2">
                  Thanks
                </Typography>

                <Typography id="modal-modal-description" sx={{ mt: 1, mb: 3, fontSize: "14px" }}>
                  Feedback sent. Thank you!
                </Typography>

                <Divider />

                <Typography sx={{ mt: 2 }} align="right">
                  <Button
                    variant="contained"
                    style={{ textTransform: "none" }}
                    onClick={() => {
                      this.setState({ sendFeedBack: false });
                    }}>
                    Close
                  </Button>
                </Typography>
              </Box>
            </Modal>
            <Modal
              open={this.state.trailSummaryModal}
              onClose={() => {
                this.handleTrailSummaryClose();
              }}
              sx={{ overflowY: "scroll", height: "auto", top: 0 }}
              aria-labelledby="modal-modal-title"
              aria-describedby="modal-modal-description">
              <Box sx={trialSummary}>
                <Grid style={{ textAlign: "right" }}>
                  <IconButton
                    onClick={() => {
                      this.setState({ trailSummaryModal: false });
                    }}>
                    <CloseIcon style={{ fontSize: "14px" }} />
                  </IconButton>
                </Grid>
                <TrialSummary
                  trail={this.state.selectedTrail}
                  droneFlightDates={droneFlightDates}
                />
              </Box>
            </Modal>
          </Box>
          {this.state.selectedTrail !== "All trials" &&
            this.state.selectedTrail !== "No trials" &&
            this.state.selectedVectorswithoutPlots[0] ? (
            this.state.loadGraph ? (
              <Button
                size="small"
                onClick={() => this.toggleGraph()}
                sx={{
                  // marginBottom: window.innerWidth > 900 ? "100px" : "72px",
                  border: "2px solid black",
                  backgroundColor: "white",
                  height: "40px",
                  margin: "0",
                  zIndex: "999999",
                  position: "absolute",
                  bottom: window.innerWidth > 440 ? "30px" : "100px",
                  marginLeft: this.state.drawerOpen ? "17%" : "10px",
                  "&:hover": {
                    backgroundColor: "white"
                  }
                }}>
                <KeyboardArrowUpIcon
                  sx={{
                    color: "black",
                    stroke: "black",
                    strokeWidth: "1px",
                    "&:hover": {
                      backgroundColor: "white"
                    }
                  }}
                />
              </Button>
            ) : (
              <Grid
                item
                sx={{
                  margin: "0",
                  zIndex: "999",
                  position: "absolute",
                  bottom: "10px",
                  overflowY: "hidden",
                  backgroundColor: "white",
                  overflowX: "hidden",
                  width: window.matchMedia("(max-width: 600px)").matches
                    ? "auto"
                    : this.state.rightDrawer && this.state.drawerOpen
                      ? "60%"
                      : this.state.rightDrawer
                        ? "64%"
                        : this.state.drawerOpen
                          ? "74%"
                          : "75%",
                  marginBottom: window.matchMedia("(max-width: 600px)").matches ? "60px" : "10px",
                  left: window.matchMedia("(max-width: 600px)").matches
                    ? 0
                    : this.state.drawerOpen
                      ? "214px"
                      : this.state.rightDrawer && this.state.drawerOpen
                        ? "14px"
                        : "100px",
                  cursor: "default",
                  right: this.state.rightDrawer ? "204px" : "0px"
                }}>
                <Button onClick={() => this.closeRightGraph()}>
                  <KeyboardArrowDownIcon
                    sx={{
                      width: "30px",
                      border: "1px solid #ccc",
                      borderRadius: "3px",
                      stroke: "black",
                      strokeWidth: "1px",
                      marginLeft: "10px",
                      position: "absolute",
                      fill: "black",
                      marginTop: "15px"
                    }}
                  />
                </Button>

                {this.state.hasTreatment ? (
                  <>
                    <Grid container sx={{ paddingLeft: "50px" }}>
                      <Grid item xs={12} sm={12} lg={6} xl={6}>
                        <Tabs
                          value={this.state.selectedTab}
                          onChange={(e, value) => {
                            this.setState({ selectedTab: value });
                          }}
                          textColor="#006D82"
                          indicatorColor="#006D82"
                          TabIndicatorProps={{
                            style: {
                              backgroundColor: "#006D82",
                              textColor: "#006D82"
                            }
                          }}>
                          <Tab
                            value="one"
                            label="Treatment Summary"
                            sx={{
                              fontWeight: 500,
                              font: "roboto",
                              "&.MuiButtonBase-root.Mui-selected": {
                                fontWeight: "bold",
                                color: "#006D82"
                              }
                            }}
                          />

                          <Tab
                            value="two"
                            label="Flight"
                            sx={{
                              fontWeight: 500,
                              font: "roboto",
                              "&.MuiButtonBase-root.Mui-selected": {
                                fontWeight: "bold",
                                color: "#006D82"
                              }
                            }}
                          />

                          <Tab
                            value="three"
                            label="Compare"
                            sx={{
                              fontWeight: 500,
                              font: "roboto",
                              "&.MuiButtonBase-root.Mui-selected": {
                                fontWeight: "bold",
                                color: "#006D82"
                              }
                            }}
                          />
                        </Tabs>
                      </Grid>
                      <Grid item xs={6} sm={6} lg={3} xl={3} style={{ textAlign: "end" }}>
                        {this.state.showDropDown === true && (
                          <div>
                            <FormControl
                              size="small"
                              sx={{
                                mt: 1,
                                mb: 1
                              }}>
                              <NativeSelect
                                id="demo-customized-select-native"
                                value={this.state.selectedValue}
                                onChange={this.handleOnChange}
                                input={<BootstrapInput />}
                                label="Choose the subplot"
                                sx={{
                                  maxwidth: 65,
                                  height: "30px"
                                }}>
                                {this.state.plotNums.map((item) => (
                                  <option key={item} value={item}>
                                    {item === "All subplots" ? item : item + " subplot"}
                                  </option>
                                ))}
                              </NativeSelect>
                            </FormControl>
                          </div>
                        )}
                      </Grid>

                      <Grid item xs={6} sm={6} lg={3} xl={3}>
                        {this.state.selectedTab === "one" && (
                          <FormControl
                            sx={{
                              display: "flex",
                              alignItems: "flex-end"
                            }}>
                            <RadioGroup
                              row
                              value={this.state.chartsValue}
                              onChange={(event) => {
                                this.setState({ chartsValue: event.target.value });
                              }}
                              defaultValue="Bar">
                              <FormControlLabel
                                value="Bar"
                                control={
                                  <Radio
                                    sx={{
                                      "&.Mui-checked": {
                                        color: "blue"
                                      }
                                    }}
                                  />
                                }
                                label="Bar"
                              />

                              <FormControlLabel
                                value="Box"
                                control={
                                  <Radio
                                    sx={{
                                      "&.Mui-checked": {
                                        color: "blue"
                                      }
                                    }}
                                  />
                                }
                                label="Box"
                              />
                            </RadioGroup>
                          </FormControl>
                        )}
                      </Grid>
                    </Grid>

                    <BarChart
                      assessment={this.state.selectedVectorswithoutPlots[0]}
                      assessment1={this.state.selectedVectorswithoutPlots[1]}
                      trailname={this.state.selectedTrail}
                      measurements={this.state.allMeasurments}
                      collectionId={this.state.selectedDateCollectionId}
                      tabIndex={this.state.selectedTab}
                      hasTreatment={this.state.hasTreatment}
                      selectedValue={this.state.selectedValue}
                      chartsValue={this.state.chartsValue}
                      rightDrawer={this.state.rightDrawer}
                    />
                  </>
                ) : (
                  <Typography
                    variant="title"
                    style={{
                      display: "block",
                      textAlign: "center",
                      marginBottom: "15%",
                      width: "100%",
                      height: "180px"
                    }}>
                    <strong>Treatment information is unavailable</strong>
                  </Typography>
                )}
              </Grid>
            )
          ) : null}
          {!this.state.rightDrawer ? (
            <Button
              size="large"
              onClick={() => this.toggleRightDrawer()}
              sx={{
                zIndex: "999",
                position: "absolute",
                right: "0",
                top: "100px",
                marginLeft: "10px",
                border: "2px solid black",
                backgroundColor: "white",
                height: "40px",
                marginRight: "24px",
                "&:hover": {
                  backgroundColor: "white"
                }
              }}>
              <ChevronLeftIcon
                sx={{
                  color: "black",
                  stroke: "black",
                  strokeWidth: "1px",
                  "&:hover": {
                    backgroundColor: "white"
                  }
                }}
              />
            </Button>
          ) : (
            <div
              style={{
                position: "fixed",
                zIndex: "999999",
                justifyContent: "flex-end",
                right: "0",
                top: "55px",
                minWidth: "270px",
                display: "flex",
                flexDirection: "column",
                backgroundColor: "white",
                color: "black",
                border: "2px solid rgba(0, 0, 0, 0.2)",
                borderRadius: "5px",
                marginRight: "10px",
                boxShadow: "0 1px 5px rgba(0, 0, 0, 0.4)",
                overflowY: "hidden"
              }}>
              <div>
                <RadioGroup
                  sx={{ padding: "2px 10px" }}
                  aria-labelledby="demo-radio-buttons-group-label"
                  defaultValue="Base"
                  name="radio-buttons-group"
                  onChange={(e) => {
                    this.rasterChange(e.target.value);
                  }}>
                  {this.state.rasterLayersList != null &&
                    Object.values(this.state.rasterLayersList)?.map((text, index) => (
                      <div>
                        <FormControlLabel
                          sx={{
                            "& .MuiFormControlLabel-label": { fontSize: "12px" },
                            height: "20px",
                            position: index === 0 ? "absolute" : ""
                          }}
                          key={text}
                          value={text}
                          control={
                            <Radio
                              size="small"
                              sx={{
                                "&.Mui-checked": {
                                  color: "blue"
                                },
                                padding: "5px 0 5px 5px",
                                transform: "scale(0.8)"
                              }}
                              checked={this.state.currentRaster === text}
                            />
                          }
                          label={text}
                        />

                        {index === 0 && (
                          <ChevronRightIcon
                            sx={{
                              width: "30px",
                              border: "1px solid #ccc",
                              borderRadius: "3px",
                              position: index === 0 ? "relative" : "",
                              float: "right",
                              display: "block",
                              marginBottom: "-4px",
                              stroke: "black",
                              strokeWidth: "1px"
                            }}
                            onClick={() => this.closeRightDrawer()}
                          />
                        )}
                      </div>
                    ))}
                </RadioGroup>
              </div>
              <Divider />
              <div>
                <fieldset>
                  {this.state.overlayLayersList != null &&
                    Object.values(this.state.overlayLayersList)?.map((text, index) => (
                      <div key={text}>
                        <label htmlFor={"chkOver" + index}>
                          <input
                            type="checkbox"
                            checked={this.state.activeOverlays.some((obj) =>
                              obj === text ? true : false
                            )}
                            id={"chkOver" + index}
                            value={text}
                            onChange={(e) => {
                              this.overlayChecklist(e, text);
                            }}
                          />
                          {text}
                        </label>
                      </div>
                    ))}
                  {this.state.overlayVectors != null && (
                    <div style={{ margin: 0, lineHeight: "18px" }}>
                      <label htmlFor="chkoverlayVectors">
                        <input
                          type="checkbox"
                          checked={this.state.selectedBoundingBoxes}
                          id="chkoverlayVectors"
                          value="Bounding Box"
                          onChange={(e) => {
                            this.boundingBoxes(e);
                          }}
                        />{" "}
                        Bounding Box
                      </label>
                    </div>
                  )}
                </fieldset>
              </div>
              <Divider />
              <div style={{ display: "inline-block" }}>
                <fieldset>
                  {this.state.activerVectorLayers != null &&
                    Object.values(this.state.activerVectorLayers)?.map((text, index) => (
                      <div
                        key={text}
                        style={{
                          margin: 0,
                          lineHeight: "18px",
                          display: "flex",
                          justifyContent: "space-between"
                        }}>
                        <label htmlFor={"chkAct" + index}>
                          <input
                            type="checkbox"
                            checked={this.state.selectedVectors.some((obj) =>
                              obj === text ? true : false
                            )}
                            id={"chkAct" + index}
                            value={text}
                            onChange={(e) => {
                              this.plotVectorChanged(e);
                            }}
                          />
                          {this.state.selectedVector === text && this.state.eyeOpenClosed ? (
                            <span style={{ fontWeight: "bold" }}>{text.split("v:")[0]}</span>
                          ) : (
                            text.split("v:")[0]
                          )}
                        </label>
                        {this.state.selectedVectors.includes(text) ? (
                          <span style={{ textAlign: "right", float: "right" }}>
                            {this.state.eyeOpenClosed === false ? (
                              <a
                                style={{ float: "right" }}
                                onClick={() => {
                                  this.setState({ eyeOpenClosed: true }, () => {
                                    window.eyeOpenClosed = true;
                                    this.setState({ selectedVectorForEyeOpen: text }, () => {
                                      this.plotEyeOpenClosed({ text }, this.state.isHeatMapActive);
                                    });
                                  });
                                }}>
                                <VisibilityIcon sx={{ fontSize: "14px" }} />
                              </a>
                            ) : this.state.selectedVectorForEyeOpen === text ? (
                              <a
                                style={{ float: "right" }}
                                onClick={() => {
                                  this.setState({ eyeOpenClosed: false }, () => {
                                    window.eyeOpenClosed = false;
                                    this.setState({ selectedVectorForEyeOpen: "" }, () => {
                                      this.plotEyeOpenClosed(undefined, this.state.isHeatMapActive);
                                    });
                                  });
                                }}>
                                <VisibilityOffIcon sx={{ fontSize: "14px" }} />
                              </a>
                            ) : (
                              <a
                                style={{ float: "right" }}
                                onClick={() => {
                                  this.setState({ eyeOpenClosed: true }, () => {
                                    window.eyeOpenClosed = true;
                                    this.setState({ selectedVectorForEyeOpen: text }, () => {
                                      this.plotEyeOpenClosed({ text }, this.state.isHeatMapActive);
                                    });
                                  });
                                }}>
                                <VisibilityIcon sx={{ fontSize: "14px" }} />
                              </a>
                            )}
                          </span>
                        ) : null}
                      </div>
                    ))}
                  {this.state.selectedVectorswithoutPlots != null &&
                    Object.values(this.state.selectedVectorswithoutPlots)?.map((text, index) => (
                      <div
                        style={{
                          margin: 0,
                          lineHeight: "18px",
                          display: "flex",
                          justifyContent: "space-between"
                        }}>
                        <label htmlFor={"chkSelected" + index}>
                          <input
                            type="checkbox"
                            checked
                            id={"chkSelected" + index}
                            value={text.vector}
                            onChange={(e) => {
                              this.vectorChangedRemove(e);
                            }}
                          />
                          <span style={{ fontWeight: "bold" }}>{text.letter}</span>
                          <span style={{ fontWeight: "bold" }}>:</span>
                          <span>
                            {this.state.selectedVector === text.vector &&
                              this.state.eyeOpenClosed ? (
                              <span style={{ fontWeight: "bold" }}>
                                {text.vector.split("v:")[0]}
                              </span>
                            ) : (
                              text.vector.split("v:")[0]
                            )}
                          </span>
                        </label>
                        <span style={{ textAlign: "right", float: "right" }}>
                          {this.state.selectedTrail !== "No trials" &&
                            this.state.isHeatMapActive === false ? (
                            <a
                              style={{ float: "right", "padding-left": "5px" }}
                              onClick={() => {
                                window.isHeatMapActive = true;
                                this.switchHeatMap({ text });
                              }}>
                              <HeatmapIconOff style={{ height: "14px", width: "14px" }} />
                            </a>
                          ) : this.state.selectedTrail !== "No trials" &&
                            this.state.selectedVectorForHeatMap === text.vector ? (
                            <a
                              style={{ float: "right", "padding-left": "5px" }}
                              onClick={() => {
                                window.isHeatMapActive = false;
                                this.switchHeatMap();
                              }}>
                              <HeatmapIconOn style={{ height: "14px", width: "14px" }} />
                            </a>
                          ) : (
                            this.state.selectedTrail !== "No trials" && (
                              <a
                                style={{ float: "right", "padding-left": "5px" }}
                                onClick={() => {
                                  window.isHeatMapActive = true;
                                  this.switchHeatMap({ text });
                                }}>
                                <HeatmapIconOff style={{ height: "14px", width: "14px" }} />
                              </a>
                            )
                          )}

                          {this.state.eyeOpenClosed === false ? (
                            <a
                              style={{ float: "right" }}
                              onClick={() => {
                                this.setState({ eyeOpenClosed: true }, () => {
                                  window.eyeOpenClosed = true;
                                  this.setState({ selectedVectorForEyeOpen: text }, () => {
                                    this.eyeOpenClosed({ text }, this.state.isHeatMapActive);
                                  });
                                });
                              }}>
                              <VisibilityIcon sx={{ fontSize: "14px" }} />
                            </a>
                          ) : this.state.selectedVectorForEyeOpen?.vector === text?.vector ? (
                            <a
                              style={{ float: "right" }}
                              onClick={() => {
                                this.setState({ eyeOpenClosed: false }, () => {
                                  window.eyeOpenClosed = false;
                                  this.setState({ selectedVectorForEyeOpen: "" }, () => {
                                    this.eyeOpenClosed(undefined, this.state.isHeatMapActive);
                                  });
                                });
                              }}>
                              <VisibilityOffIcon sx={{ fontSize: "14px" }} />
                            </a>
                          ) : (
                            <a
                              style={{ float: "right" }}
                              onClick={() => {
                                this.setState({ eyeOpenClosed: true }, () => {
                                  window.eyeOpenClosed = true;
                                  this.setState({ selectedVectorForEyeOpen: text }, () => {
                                    this.eyeOpenClosed({ text }, this.state.isHeatMapActive);
                                  });
                                });
                              }}>
                              <VisibilityIcon sx={{ fontSize: "14px" }} />
                            </a>
                          )}
                        </span>
                      </div>
                    ))}
                </fieldset>
              </div>
              {this.state.isChecked && (
                <a
                  style={{ padding: "2px 10px" }}
                  href="#"
                  onClick={() => {
                    this.clearAll();
                  }}>
                  Clear all
                </a>
              )}
              <Divider />
              <div>
                <div id="Resizable" style={resizableStyle}>
                  <fieldset>
                    {this.state.inActiverVectorLayers != null &&
                      Object.values(this.state.inActiverVectorLayers)?.map((text, index) =>
                        text.includes("Title") ? (
                          <div key={text}>
                            <strong>{text.replace("Title:", "")}</strong>
                          </div>
                        ) : (
                          <div key={text} style={{ margin: 0, lineHeight: "18px" }}>
                            <label htmlFor={"chkRaster" + index}>
                              <input
                                type="checkbox"
                                checked={false}
                                id={"chkRaster" + index}
                                value={text}
                                onChange={(e) => {
                                  this.vectorChanged(e);
                                }}
                              />
                              {text.split("v:")[0]}
                            </label>
                          </div>
                        )
                      )}
                  </fieldset>
                </div>

                <div style={{ marginTop: "1px !important" }}>
                  {this.state.inActiverVectorLayers != null && (
                    <div style={{ margin: "" }}>
                      {this.state.inActiverVectorLayers != null && (
                        <Typography
                          id="modal-modal-description"
                          sx={{ mt: 1, padding: "8px", marginTop: "0px !important" }}>
                          <TextField
                            id="findRating"
                            size="small"
                            label={
                              <Typography sx={{ fontSize: "12px", fontFamily: "Helvetica" }}>
                                Find ratings...
                              </Typography>
                            }
                            fullWidth
                            autoComplete="off"
                            sx={{
                              fontSize: "12px",
                              "& label.Mui-focused": {
                                color: "black",
                                borderRadius: "1px",
                                zIndex: "99"
                              },
                              "& .MuiInputBase-root": {
                                height: 80
                              },
                              "& .MuiOutlinedInput-root": {
                                "&.Mui-focused fieldset": {
                                  borderColor: "#66afe9",
                                  boxShadow:
                                    "inset 0 1px 1px rgba(0,0,0,.075), 0 0 8px rgba(102,175,233,.6)",
                                  zIndex: "99"
                                }
                              }
                            }}
                            onChange={(e) => {
                              this.vectorLayersFilter(e.target.value);
                            }}
                            value={this.state.filterValue}
                            InputProps={{
                              inputMode: "text",
                              type: "text",
                              style: { fontSize: "12px", height: "35px" },
                              endAdornment: this.state.filterValue !== "" && (
                                <IconButton
                                  aria-label="toggle password visibility"
                                  onClick={() => {
                                    this.setState({ filterValue: "" }, () => {
                                      this.vectorLayersFilter("");
                                    });
                                  }}>
                                  <CloseIcon style={{ fontSize: "12px" }} />
                                </IconButton>
                              )
                            }}
                          />
                        </Typography>
                      )}
                    </div>
                  )}
                </div>
                <div
                  id="Draggable"
                  onMouseDown={this.handleMouseDown}
                  onTouchStart={(e) => {
                    this.touchStart(e);
                  }}
                  onTouchMove={(e) => {
                    this.touchMove(e);
                  }}
                  onTouchEnd={() => {
                    this.touchEnd();
                  }}
                />
              </div>
            </div>
          )}
        </div>
      </Box>
    );
  }
}

ViewerComponent.propTypes = {
  window: PropTypes.func,
  navigate: PropTypes.any
};
export default withRouter(ViewerComponent);